import { AnimatePresence, motion } from "framer-motion"

import create from "zustand"
import { persist } from "zustand/middleware"

export function CookieInformer() {
  const isOpen = useStore(state => state.isOpen)
  const close = () => useStore.setState({ isOpen: false })

  return (
    <AnimatePresence>
      {isOpen && (
        <div className="fixed left-0 bottom-0 right-0 z-10 text-white">
          <motion.div
            variants={motion1}
            initial="initial"
            animate="animate"
            exit="exit"
            className="absolute left-0 right-0 bottom-0 top-0 origin-bottom bg-black"
          />
          <motion.div
            variants={motion2}
            initial="initial"
            animate="animate"
            exit="exit"
            className="relative z-[1] flex"
          >
            <div className="flex-1 p-6 text-sm opacity-70">
              Наш сайт использует файлы cookie и похожие технологии, предоставляя персональную информацию. При
              использовании данного сайта, вы подтверждаете свое{" "}
              <a
                href={`${process.env.PUBLIC_URL}/docs/soglasie.pdf`}
                target="_blank"
                rel="noreferrer"
                className="hover:underline"
              >
                согласие
              </a>{" "}
              на использование cookie файлов и обработку персональных общедоступных данных согласно{" "}
              <a
                href={`${process.env.PUBLIC_URL}/docs/politics.pdf`}
                target="_blank"
                rel="noreferrer"
                className="hover:underline"
              >
                "Политике конфиденциальности"
              </a>
            </div>
            <button
              onClick={close}
              title="Закрыть"
              aria-label="Закрыть"
              className="p-6 opacity-70 hover:text-pink-100 hover:opacity-100"
            >
              ✕
            </button>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  )
}

const useStore = create(
  persist(
    () => ({
      isOpen: true
    }),
    { name: "cookie-storage" }
  )
)

const motion1 = {
  initial: {
    scaleY: 0
  },
  animate: {
    scaleY: 1,
    transition: {
      delay: 0.5
    }
  },
  exit: {
    scaleY: 0,
    transition: {
      delay: 0.5
    }
  }
}

const motion2 = {
  initial: {
    opacity: 0,
    y: "100%"
  },
  animate: {
    opacity: 1,
    y: "0",
    transition: {
      delay: 1
    }
  },
  exit: {
    opacity: 0,
    y: "100%"
  }
}
