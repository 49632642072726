import "swiper/css"
import "swiper/css/autoplay"
import "swiper/css/effect-fade"
import "swiper/css/navigation"

import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Autoplay, EffectFade, Navigation } from "swiper"

import { ReactComponent as ArrowNextSmIcon } from "../assets/arrow-next-sm.svg"
import { ReactComponent as ArrowPrevSmIcon } from "../assets/arrow-prev-sm.svg"
import { IconButton } from "shared/components/navigation"
import { useBanners } from "../api"
import { useState } from "react"
import { useThrowError } from "shared/hooks"

SwiperCore.use([Autoplay, EffectFade, Navigation])

export function HeroSm() {
  const { error, data: { data: banners = [] } = {} } = useBanners()
  useThrowError(error)
  const [active, setActive] = useState(0)

  const navigation = {
    prevEl: ".swiper-prev",
    nextEl: ".swiper-next"
  }

  return (
    <>
      <div className="relative">
        <Swiper
          onSlideChange={swiper => setActive(swiper.realIndex)}
          loop={true}
          slidesPerView={1}
          effect="fade"
          className="h-[100%]"
          speed={1000}
          navigation={navigation}
          autoHeight={true}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false
          }}
        >
          {banners.map(banner => (
            <SwiperSlide key={banner.id}>
              <div className="bg-white px-[16px] pt-[20px]">
                <div className="h1 break-words text-[24px]" dangerouslySetInnerHTML={{ __html: banner.name }} />
                <div className="mt-[20px]">
                  <div className="aspect-[288/152] w-[100%]">
                    <img src={banner.pictureUrl} className="block h-[100%] w-[100%] object-cover" alt="" />
                  </div>
                  <div className="text1 mt-[20px] text-[12px]">{banner.subName}</div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="mt-[30px] flex items-center px-[16px] pb-[26px]">
          <div className="flex-none">
            <div className="text-right text-[12px] leading-[1.55]">
              {active + 1}/{banners.length}
            </div>
            <div className="mt-[6px] flex gap-[40px]">
              <div className="swiper-prev cursor-pointer">
                <ArrowPrevSmIcon />
              </div>
              <div className="swiper-next cursor-pointer">
                <ArrowNextSmIcon />
              </div>
            </div>
          </div>
          {banners.length && banners[active].link && (
            <div className="flex flex-auto justify-end pt-[15px]">
              <a href={banners[active].link} target={banners[active].linkTarget} rel="noreferrer" className="group">
                <IconButton icon="arrowUp" />
              </a>
            </div>
          )}
        </div>
      </div>
    </>
  )
}
