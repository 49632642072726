import { ReactComponent as FlatIcon } from "../assets/flat.svg"
import { ReactComponent as FreeIcon } from "../assets/free.svg"
import { RoundText } from "./roundText"
import { ReactComponent as StarIcon } from "../assets/star.svg"
import { SwiperSm } from "./swiper-sm"
import { cx } from "shared/helpers"
import { useState } from "react"

export function AdvantagesSm({ swiper }) {
  const [swiperObject, setSwiperObject] = useState()

  return (
    <div className="mt-[60px] md:mt-[120px] xl:mt-[150px]">
      <div className="text2 flex-none text-gray-500">Преимущества</div>
      <div className="border-1 mt-[15px] border-t border-gray-300">
        <Advantage swiper={swiperObject} index={0}>
          Инновационный фасад
        </Advantage>
        <Advantage swiper={swiperObject} index={1}>
          Витражное остекление
        </Advantage>
        <div className="grid-4 items-center border-b border-gray-300">
          <div className="col-start-1 col-end-4">
            <Advantage swiper={swiperObject} index={2}>
              отделка White box
            </Advantage>
            <Advantage swiper={swiperObject} index={3} border={false}>
              кондиционер и теплый пол
            </Advantage>
          </div>
          <div className="col-start-4 col-end-5 flex justify-center">
            <RoundText icon={<StarIcon className="block h-[9px] w-[9px]" />}>
              <FlatIcon />
            </RoundText>
          </div>
        </div>
      </div>
      <div className="mt-[30px]">
        <SwiperSm data={swiper} onSwiper={setSwiperObject} />
      </div>
      <div className="border-1 mt-[30px] border-t border-gray-300">
        <div className="grid-4 items-center border-b border-gray-300">
          <div className="col-start-1 col-end-4">
            <Advantage swiper={swiperObject} index={4}>
              свой тренажерный зал
            </Advantage>
            <Advantage swiper={swiperObject} index={5} border={false}>
              митинг рум
            </Advantage>
          </div>
          <div className="col-start-4 col-end-5 flex justify-center">
            <RoundText icon={<StarIcon className="block h-[9px] w-[9px]" />}>
              <FreeIcon />
            </RoundText>
          </div>
        </div>
        <Advantage swiper={swiperObject} index={6}>
          Face ID
        </Advantage>
        <Advantage swiper={swiperObject} index={7}>
          колясочные
        </Advantage>
        <Advantage swiper={swiperObject} index={8}>
          лифты
        </Advantage>
      </div>
    </div>
  )
}

const Advantage = ({ children, border = true, swiper, index, ...props }) => (
  <div
    onClick={() => {
      swiper.slideTo(index)
    }}
    className={cx("h7 cursor-pointer py-[15px] text-[12px] hover:text-pink-100", {
      "border-b border-gray-300": border
    })}
    {...props}
  >
    {children}
  </div>
)
