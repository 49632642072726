import { Collapse } from "../components/collapse"
import { Form } from "./form"
export function Innovation({ data }) {
  return (
    <div className="mt-[60px] md:mt-[120px] xl:mt-[150px]">
      <div className="text2 flex-none text-gray-500">Инновационный фасад</div>
      <div className="mt-[15px] flex justify-center max-md:hidden md:mt-[20px] xl:mt-[40px]">
        <div className="flex items-center gap-[20px] md:mt-[15px] xl:mt-[54px]">
          <div className="h6 font-bold">до</div>
          <div className="font-medium leading-[1.2] md:text-[70px] xl:text-[90px]">400 000</div>
          <div className="font-normal leading-[1.1] md:text-[14px] xl:text-[18px]">₽</div>
          <div className="h2 align-center mb-[-40px] flex gap-[40px]">
            <div className="ml-[20px] font-normal md:text-[17px] xl:text-[20px]">—</div>{" "}
            <div>
              Ежегодная экономия
              <br /> расходов на электричество
            </div>
          </div>
        </div>
      </div>
      <div className="mt-[15px] md:hidden">
        <div className="flex items-baseline gap-[14px]">
          <div className="h6 font-bold">до</div>
          <div className="text-[50px] font-medium leading-[1.2]">400 000</div>
          <div className="text-[13px] font-normal leading-[1.3]">₽</div>
        </div>
        <div className="mt-[20px]">
          <div className="align-center flex gap-[20px]">
            <div className="font-normal md:text-[15px]">—</div>{" "}
            <div className="h2">
              Ежегодная экономия
              <br /> расходов на электри&shy;чество
            </div>
          </div>
        </div>
      </div>
      <div className="mt-[40px] overflow-hidden rounded-[20px] max-md:aspect-[288/162] xl:mt-[60px]">
        <img
          loading="lazy"
          alt=""
          className="block h-[100%] w-[100%] object-cover max-md:hidden"
          src={require("../assets/b1.jpg")}
        />
        <img
          loading="lazy"
          alt=""
          className="block h-[100%] w-[100%] object-cover md:hidden"
          src={require("../assets/mb1.jpg")}
        />
      </div>
      <div className="md:grid-10 mt-[32px] md:mt-[40px] xl:mt-[60px]">
        <div className="h2 col-start-2 col-end-5">Инновационный энергогенерирующий фасад</div>
        <div className="col-start-7 col-end-10 max-md:mt-[30px]">
          <div className="text1">
            Южная часть фасада Умного дома Гелиос будет состоять из фотоэлектрических элементов «Unigreen», которые
            будут генерировать энергию солнца и превращать её в электричество.
            <br />
            <br />
            Выработанное электричество пойдет на общедомовые нужды, освещение мест общего пользования: коридоры, лифты,
            холлы и т.п.
          </div>
          <div className="mt-[40px] md:mt-[30px] xl:mt-[40px]">
            <Collapse data={data} />
          </div>
        </div>
      </div>
      <Form requestType="ipoteka">
        <div className="h2">
          квартиры
          <br className="md:hidden" /> в&nbsp;рассрочку до&nbsp;конца строительства
        </div>
      </Form>
    </div>
  )
}
