import { advantages } from "./advantages"
import { apartments } from "./apartments"
import { apartmentsSwiper } from "./apartments-swiper"
import { infrastructure } from "./infrastructure"
import { infrastructureSwiper } from "./infrastructure-swiper"
import { innovation } from "./innovation"
import { live } from "./live"
import { liveSwiper } from "./live-swiper"
import { location } from "./location"
import { pano } from "./pano"
import { parking } from "./parking"
import { plansSwiper } from "./plans-swiper"
import { security } from "./security"

export const mock = {
  innovation,
  advantages,
  location,
  pano,
  live,
  liveSwiper,
  infrastructure,
  infrastructureSwiper,
  security,
  parking,
  apartments,
  apartmentsSwiper,
  plansSwiper
}
