import { Button } from "shared/components/navigation"
import { Collapse } from "./collapse"
import { Suggest } from "shared/components/suggest"
import { sanitizeHTML } from "shared/helpers"
import { useContacts } from "shared/store"
import { useState } from "react"

export function Location({ data }) {
  const { address } = useContacts()
  const [targetCoords, setTargetCoords] = useState([])

  const buildRoute = () => {
    if (targetCoords.length > 0)
      window.open(`https://yandex.ru/maps/?rtext=54.725857, 55.99678~${targetCoords[0]},${targetCoords[1]}`, "_blank")
  }

  return (
    <div className="mt-[60px] md:mt-[120px] xl:mt-[150px]">
      <div className="text2 flex-none text-gray-500">Расположение</div>
      <div className="md:grid-10 mt-[15px] md:mt-[20px]">
        <div className="col-start-1 col-end-4">
          <div className="h2 whitespace-pre-line" dangerouslySetInnerHTML={{ __html: sanitizeHTML(address.complex) }} />
          <div className="text1 mt-[10px]">Рядом с центром и парками</div>
          <div className="mt-[30px] items-center gap-[20px] md:mt-[20px] xl:mt-[73px]">
            <Suggest
              className="w-[100%] max-w-[100%] md:max-w-[273px] xl:max-w-[364px]"
              onSelect={item => {
                setTargetCoords([item.data.geoLat, item.data.geoLon])
              }}
            />
            <div className="mt-[30px] flex items-center gap-[30px] xl:mt-[40px] xl:gap-[36px]">
              <div>
                <div className="max-md:hidden">
                  <Button variant="secondary" size="middle" onClick={buildRoute}>
                    построить маршрут
                  </Button>
                </div>
                <div className="md:hidden">
                  <Button variant="secondary" onClick={buildRoute}>
                    построить маршрут
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-start-4 col-end-8 max-md:mt-[40px]">
          <div className="relative md:px-[20px] xl:pl-0 xl:pr-[50px]">
            <img loading="lazy" alt="Карта" className="block w-[100%]" src={require("../assets/map.svg").default} />
            <div className="button-text absolute top-[0] left-[0] hover:text-pink-100 md:bottom-[20px] md:right-[20px] md:left-[auto] md:top-[auto]">
              <a
                href="https://yandex.ru/maps/172/ufa/house/ulitsa_gali_ibragimova_5_3/YUwYfw5mTk0HQFtufXtzdHRmYw==/inside/?l=sat%2Cskl&ll=55.997161%2C54.725760&tab=inside&z=17"
                target="_blank"
                rel="noreferrer"
                className="max-md:hidden"
              >
                открыть на картах
              </a>
              <a
                href="yandexmaps://maps.yandex.ru/?ll=55.997161,54.725760&tab=inside&z=17"
                target="_blank"
                rel="noreferrer"
                className="md:hidden"
              >
                открыть на картах
              </a>
            </div>
          </div>
        </div>
        <div className="col-start-8 col-end-11 max-md:mt-[30px]">
          <Collapse data={data} />
        </div>
      </div>
    </div>
  )
}
