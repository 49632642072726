import { debounce, get } from "lodash"
import { prefetchPlacement, useFloor } from "../api"
import { useCallback, useMemo } from "react"
import { useSeo, useThrowError } from "shared/hooks"

import { Compass } from "../components/compass"
import { FloorPickerWidget } from "../components/floor-picker-widget"
import { FloorSchema } from "../components/floor-schema"
import { GenplanSchema } from "../components/genplan-schema"
import { Legend } from "../components/legend"
import { MetaTags } from "shared/components/seo"
import { PlacementTooltip } from "../components/placement-tooltip"
import { motion } from "framer-motion"
import { useParams } from "react-router-dom"
import { useState } from "react"

export function FloorPage() {
  const [activePlacement, setActivePlacement] = useState()

  const params = useParams()
  const floor = params.floor ? +params.floor : void 0
  const { building, section } = params

  const { error, data: { data, meta } = {} } = useFloor({ building, section, floor })
  useThrowError(error)
  const { title, description } = useSeo(meta?.seo)

  const debouncedPrefetch = useMemo(() => {
    return debounce(prefetchPlacement, 100)
  }, [])

  const openTooltip = useCallback(
    (ev, placement) => {
      debouncedPrefetch(placement.uniCode)
      setActivePlacement(placement)
    },
    [debouncedPrefetch]
  )

  const closeTooltip = useCallback(() => {
    setActivePlacement(null)
  }, [])

  const getVal = useCallback(
    (key, defaultValue) => {
      const value = get(data?.currentFloor, key)
      return value || defaultValue
    },
    [data]
  )

  const currentFloor = floor ?? getVal("placements[0].floor")
  const placements = getVal("placements", [])
  const compasDegree = getVal("compasDegree", 0)
  const viewbox = getVal("floorPlanViewbox", "0 0 1781 838")
  const floorPlan = getVal("floorPlan")

  return (
    <div className="wrap top-0 bottom-0 right-0 left-0 md:absolute md:py-[60px] xl:py-[100px]">
      <MetaTags title={title} description={description} />
      <div className="md:grid-10 h-[100%]">
        <div className="md:hidden">
          <FloorPickerWidget building={building} section={section} variant="sm" floor={currentFloor} />
        </div>
        <div className="relative col-start-1 col-end-3 h-[100%] overflow-hidden max-md:hidden">
          {activePlacement && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ default: { ease: "easeOut", duration: 0.5, delay: 0.2 } }}
            >
              <PlacementTooltip placement={activePlacement} />
            </motion.div>
          )}
          {!activePlacement && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ default: { ease: "easeOut", duration: 0.5, delay: 0.2 } }}
              className="relative z-[1] h-[100%] overflow-hidden"
            >
              <FloorPickerWidget building={building} section={section} floor={currentFloor} />
            </motion.div>
          )}
          <div className="absolute bottom-0 right-0 left-0 grid grid-cols-2 gap-[20px]">
            <div className="col-start-2 col-end-3 px-[10px] xl:justify-self-end">
              <Compass deg={compasDegree} />
            </div>
          </div>
        </div>
        <div className="col-start-3 col-end-9 max-md:mt-[20px]">
          <div className="h-[100%] flex-col md:flex md:gap-[30px] xl:gap-[60px]">
            <div className="select-none md:flex-none md:px-[25px] xl:px-[87px]">
              <img alt="" src={require("../assets/trees.svg").default} className="max-md:hidden" />
              <img alt="" src={require("../assets/trees-sm.svg").default} className="md:hidden" />
            </div>
            <div className="relative max-md:mt-[20px] md:flex-auto">
              <div className="absolute top-0 bottom-0 right-0 left-0 max-md:hidden">
                <FloorSchema
                  viewbox={viewbox}
                  schema={floorPlan}
                  placements={placements}
                  onMouseEnter={openTooltip}
                  onMouseLeave={closeTooltip}
                />
              </div>
              <div className="md:hidden">
                <FloorSchema
                  viewbox={viewbox}
                  schema={floorPlan}
                  placements={placements}
                  onClick={openTooltip}
                  variant="sm"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-start-9 col-end-11 flex flex-col max-md:mt-[30px]">
          <div className="flex-auto max-md:flex max-md:items-center">
            <div className="flex-auto">
              <Legend />
            </div>
            <div className="flex-none px-[12px] md:hidden">
              <Compass deg={compasDegree} />
            </div>
          </div>
          <div className="flex-none max-md:mt-[40px]">
            <div>
              <GenplanSchema selectedBuildings={{ [+building]: [+section] }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
