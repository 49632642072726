import { ReactComponent as MinusIcon } from "../assets/minus.svg"
import { motion } from "framer-motion"
import { useState } from "react"

export function Collapse({ data }) {
  const [active, setActive] = useState()

  const toggle = id => {
    setActive(active === id ? null : id)
  }

  return (
    <div className="border-1 border-t border-gray-300">
      {data.map(item => (
        <CollapseRow
          key={item.id}
          item={item}
          isOpened={active === item.id}
          onClick={() => {
            toggle(item.id)
          }}
        />
      ))}
    </div>
  )
}

const CollapseRow = ({ item, isOpened, onClick }) => {
  const variants = {
    initial: { height: 0 },
    animate: {
      height: "max-content"
    }
  }
  return (
    <div className="border-1 group overflow-hidden border-b border-gray-300">
      <div className="flex cursor-pointer items-center py-[15px] md:py-[20px] xl:py-[25px]" onClick={onClick}>
        <div className="h7 flex-auto font-semibold max-md:text-[12px]">{item.name}</div>
        <div className="relative flex-none text-gray-500 transition group-hover:text-pink-100">
          <MinusIcon className="block h-auto w-[17px] md:w-[20px] xl:w-[24px]" />
          <motion.div
            initial={{ rotate: 90 }}
            animate={{
              rotate: isOpened ? 0 : 90
            }}
            transition={{ default: { ease: "easeOut" } }}
            className="absolute top-0 rotate-90"
          >
            <MinusIcon className="block h-auto w-[17px] md:w-[20px] xl:w-[24px]" />
          </motion.div>
        </div>
      </div>
      <motion.div
        key={item.id}
        variants={variants}
        initial="initial"
        animate={isOpened ? "animate" : "initial"}
        transition={{ default: { ease: "easeOut" } }}
        className="text4 after:block after:h-[15px] after:content-[''] md:after:h-[20px] xl:after:h-[25px]"
      >
        {item.text}
      </motion.div>
    </div>
  )
}
