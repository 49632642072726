import { ErrorLabel, SubmitSuccess } from "shared/components/forms"

import { Button } from "shared/components/navigation"
import { ReactComponent as OkIcon } from "../assets/ok.svg"
import { PatternFormat } from "react-number-format"
import { useForm } from "shared/hooks"
import { useState } from "react"

export function Form({ children, requestType = "callback" }) {
  const [formatState, setFormatState] = useState(false)
  const { state, setValue, submit, error, response, reset } = useForm({
    requestType: requestType,
    phone: "",
    name: ""
  })

  return (
    <div className="mt-[60px] items-center gap-[20px] rounded-[20px] bg-gray-600 px-[20px] py-[30px] md:mt-[40px] md:flex md:p-[60px] xl:mt-[150px] xl:p-[60px]">
      <div className="flex-1">{children}</div>
      <div className="flex flex-1 justify-center max-md:mt-[30px]">
        <div className="max-w-[100%] md:max-w-[312px] xl:max-w-[364px]">
          {response ? (
            <SubmitSuccess variant="landing" />
          ) : (
            <>
              <div>
                <input
                  placeholder="имя"
                  value={state.name}
                  className="input w-[100%] bg-gray-600"
                  onChange={ev => setValue("name", ev.target.value)}
                />
                {error.name && <ErrorLabel message={error.name} />}
              </div>
              <div className="mt-[20px] xl:mt-[30px]">
                <div>
                  <PatternFormat
                    placeholder="телефон"
                    value={state.phone}
                    mask="_"
                    allowEmptyFormatting={formatState && !state.phone}
                    format={`+7 (###) ###-##-##`}
                    className="input w-[100%] bg-gray-600"
                    onChange={ev => setValue("phone", ev.target.value === "+7 (___) ___-__-__" ? "" : ev.target.value)}
                    onMouseEnter={() => setFormatState(true)}
                    onMouseLeave={() => {
                      setFormatState(false)
                    }}
                  />
                </div>
                {error.phone && <ErrorLabel message={error.phone} />}
              </div>
              <div className="mt-[10px] text-[10px] font-medium leading-[1.2] text-gray-500 md:mt-[20px] xl:mt-[30px]">
                Нажимая кнопку “Заказать звонок”,
                <br className="max-md:hidden" />
                вы даете{" "}
                <a
                  href={`${process.env.PUBLIC_URL}/docs/soglasie.pdf`}
                  target="_blank"
                  rel="noreferrer"
                  className="hover:underline"
                >
                  согласие
                </a>{" "}
                на обработку персональных данных
              </div>
            </>
          )}
        </div>
      </div>
      <div className="flex-1 max-md:mt-[30px] max-md:flex max-md:justify-center">
        <div className="md:pl-[10%]">
          {response ? (
            <Button onClick={reset}>
              <OkIcon />
            </Button>
          ) : (
            <Button onClick={submit}>Заказать звонок</Button>
          )}
        </div>
      </div>
    </div>
  )
}
