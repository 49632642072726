export const pano = [
  {
    id: 0,
    name: "витражное остекление",
    text: (
      <>
        Панорамное остекление от пола до потолка в каждой квартире, а также алюминиевые конструкции с увеличенным
        световым проемом.
      </>
    )
  },
  {
    id: 1,
    name: "алюминиевые профили",
    text: (
      <>
        Надежные и стильные алюминиевые профили на фасадных конструкциях дома качественно отличаются от типовых ПВХ-окон
        в прочих жилых комплексах.
      </>
    )
  }
]
