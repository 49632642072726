export const infrastructure = [
  {
    id: 0,
    name: "сКОРОСТНЫЕ ЛИФТЫ",
    text: <>Дом будет оборудован четырьмя скоростными бесшумными лифтами.</>
  },
  {
    id: 1,
    name: "Колясочная на 1 этаже",
    text: (
      <>
        На первом этаже дома будет располагаться отдельной помещение колясочной. Здесь можно будет разместить детские
        коляски или велосипеды для временного хранения.
      </>
    )
  },
  {
    id: 2,
    name: "Общие кладовые на этаже",
    text: <>Общие кладовые для жителей на каждом этаже.</>
  }
]
