import create from "zustand"
import { getSettings } from "./api"
import shallow from "zustand/shallow"

const settings = create(set => ({
  contacts: {
    phone: "",
    address: {
      complex: "",
      office: ""
    },
    social: {}
  },
  seo: {
    title: "",
    description: ""
  },
  load: async () => {
    const data = (await getSettings()).data
    const complex = data.addressComplex || ""
    const office = data.addressOffice || ""
    const phone = data.phone || ""
    const social = data.social || {}
    const title = data.defaultMeta?.title || ""
    const description = data.defaultMeta?.description || ""
    set({
      contacts: {
        phone,
        address: {
          complex,
          office
        },
        social
      },
      seo: {
        title,
        description
      }
    })
  }
}))

const modal = create(set => ({
  component: null,
  close: () => set({ component: null }),
  open: component => set({ component })
}))

export const useSettings = () => {
  return settings(
    state => ({
      contacts: {
        phone: state.phone,
        address: state.address,
        social: state.social
      },
      seo: {
        title: state.title,
        description: state.description
      },
      loadSettings: state.load
    }),
    shallow
  )
}

export const useContacts = () => {
  return settings(
    state => ({
      phone: state.contacts.phone,
      address: state.contacts.address,
      social: state.contacts.social
    }),
    shallow
  )
}

export const useSeoData = () => {
  return settings(
    state => ({
      title: state.seo.title,
      description: state.seo.description
    }),
    shallow
  )
}

export const useModal = () => {
  return modal(
    state => ({
      modalComponent: state.component,
      modalIsOpen: state.component !== null,
      openModal: state.open,
      closeModal: state.close
    }),
    shallow
  )
}
