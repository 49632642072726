import { useCallback, useEffect, useState } from "react"
import { HeaderMd } from "./header-md"
import { HeaderSm } from "./header-sm"
import { useDomEvent } from "shared/hooks"

export function Header({ variant }) {
  const [newVariant, setNewVariant] = useState(variant)

  const updateVariant = useCallback(() => {
    if (variant === "overlay") {
      const condition = window.scrollY > window.innerHeight - HEADER_HEIGHT
      setNewVariant(condition ? void 0 : "overlay")
    } else {
      setNewVariant(variant)
    }
  }, [setNewVariant, variant])

  useDomEvent("scroll", updateVariant)
  useEffect(updateVariant)

  return (
    <div className="sticky top-0 z-[100]">
      <div className="block max-md:hidden">
        <HeaderMd variant={newVariant} />
      </div>
      <div className="block md:hidden">
        <HeaderSm />
      </div>
    </div>
  )
}

const HEADER_HEIGHT = 90
