import { AnimatePresence, motion } from "framer-motion"
import { forwardRef, useEffect, useRef, useState } from "react"
import { Button } from "shared/components/navigation"
import { Link } from "react-router-dom"
import { PLACEMENT_STATUS } from "../constans"
import { PlacementTooltip } from "./placement-tooltip"
import { PlacementTooltipLight } from "./placement-tooltip-light"
import Popup from "reactjs-popup"
import { PortalRenderer } from "./portal-renderer"
import { cx } from "shared/helpers"
import { useDomEvent } from "shared/hooks"
import { useNavigate } from "react-router-dom"
import { useOutsideClick } from "shared/hooks"

export function FloorSchema({
  viewbox,
  schema,
  placements,
  onMouseEnter = () => {},
  onMouseLeave,
  scalable,
  onClick = () => {},
  placementUniCode,
  variant = "default"
}) {
  const navigate = useNavigate()
  const [activePlacement, setActivePlacement] = useState()
  const [elementPosition, setElementPosition] = useState({})
  const [tooltipPosition, setTooltipPosition] = useState({})
  const ref = useRef()
  const margin = 16

  const [hover, setHover] = useState(false)
  const setHoverValue = value => {
    if (scalable) setHover(value)
  }

  const closeTooltip = () => {
    setActivePlacement(null)
  }

  useEffect(() => {
    const height = ref.current?.offsetHeight || 0
    const width = ref.current?.offsetWidth || 0
    const top = elementPosition.top - height
    const left =
      elementPosition.left > 0
        ? elementPosition.left + width > window.innerWidth
          ? window.innerWidth - width - margin
          : elementPosition.left
        : 0
    setTooltipPosition({
      top: top,
      left: left
    })
  }, [activePlacement, elementPosition])

  useDomEvent(
    "scroll",
    () => {
      closeTooltip()
    },
    []
  )

  useOutsideClick(ref, () => {
    closeTooltip()
  })

  return (
    <PortalRenderer active={hover}>
      {hover && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ default: { ease: "easeOut", delay: 0.1 } }}
          className="fixed top-0 left-0 right-0 bottom-0 bg-gray-100/60 max-md:hidden"
        />
      )}
      <div
        className={cx("relative origin-top-right bg-white delay-100", {
          "cursor-pointer bg-white md:transition md:hover:z-[10] md:hover:scale-[2]": scalable
        })}
        onMouseEnter={() => setHoverValue(true)}
        onMouseLeave={() => setHoverValue(false)}
      >
        <svg width="100%" height="100%" xmlSpace="preserve" viewBox={viewbox}>
          <image width="100%" height="100%" xlinkHref={schema} />
          {placements.map(placement => (
            <PolygonWrap
              key={placement.uniCode}
              placement={placement}
              onClick={ev => {
                setActivePlacement(placement)
                setElementPosition(ev.target.getBoundingClientRect())
                onClick(ev, placement)
              }}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              placementUniCode={placementUniCode}
              scalable={scalable}
              variant={variant}
            />
          ))}
        </svg>
      </div>
      {variant === "sm" && (
        <AnimatePresence>
          {activePlacement && (
            <motion.div
              ref={ref}
              style={{ top: `${tooltipPosition.top}px`, left: `${tooltipPosition.left}px` }}
              className="fixed top-[auto] z-[100] min-w-[262px] rounded-[20px] bg-white px-[15px] py-[20px] shadow-3xl md:hidden"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ default: { ease: "easeOut" } }}
            >
              <PlacementTooltipLight placement={activePlacement} elementPosition={elementPosition}>
                <div className="flex justify-center md:hidden">
                  <Link to={`/placement/${activePlacement.uniCode}`}>
                    <Button
                      style={{ width: 98, height: 98 }}
                      onClick={() => {
                        closeTooltip()
                        navigate(`/placement/${activePlacement.uniCode}`)
                      }}
                    >
                      смотреть
                    </Button>
                  </Link>
                </div>
              </PlacementTooltipLight>
            </motion.div>
          )}
        </AnimatePresence>
      )}
    </PortalRenderer>
  )
}

const PolygonWrap = ({ placement, onClick, onMouseEnter, onMouseLeave, placementUniCode, scalable, variant }) => {
  const ref = useRef()
  const closeTooltip = () => ref.current.close()

  if (variant === "sm")
    return (
      <Polygon
        placement={placement}
        key={placement.uniCode}
        onClick={ev => onClick(ev, placement)}
        active={placement.uniCode === placementUniCode}
      />
    )
  return (
    <Link
      to={`/placement/${placement.uniCode}`}
      onMouseEnter={ev => onMouseEnter(ev, placement)}
      onMouseLeave={onMouseLeave}
      onMouseOver={ev => {
        if (scalable && ev.target.closest("[data-tooltip]")) {
          closeTooltip()
        }
      }}
    >
      <Popup
        ref={ref}
        trigger={
          <Polygon placement={placement} active={placement.uniCode === placementUniCode} print={variant === "print"} />
        }
        on={["hover"]}
        position="left center"
        arrow={false}
        mouseLeaveDelay={200}
        disabled={!scalable}
      >
        <div className="min-w-[170px] rounded-[20px] bg-white py-[24px] px-[20px] shadow-3xl" data-tooltip>
          <PlacementTooltip variant="popup" placement={placement} />
        </div>
      </Popup>
    </Link>
  )
}

const Polygon = forwardRef(({ placement, active = false, print, ...props }, ref) => (
  <polygon
    ref={ref}
    className={cx("cursor-pointer fill-transparent stroke-[0.5%] hover:fill-pink-100 hover:opacity-20", {
      "stroke-pink-100": placement.status === PLACEMENT_STATUS.Sale && !print,
      "fill-gray-600 opacity-80": placement.status === PLACEMENT_STATUS.Booked && !print,
      "fill-gray-300": placement.status === PLACEMENT_STATUS.Sold && !print,
      "fill-pink-100/20 stroke-none": active
    })}
    points={placement.polyScheme ?? null}
    {...props}
  />
))
