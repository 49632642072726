import { Link, useParams } from "react-router-dom"
import { format, parseISO } from "date-fns"
import { useSeo, useThrowError } from "shared/hooks"

import { Button } from "shared/components/navigation"
import { Cover } from "shared/components/layout"
import { HeroImage } from "../components/hero-image"
import { MetaTags } from "shared/components/seo"
import { Pager } from "shared/components/navigation"
import { motion } from "framer-motion"
import { sanitizeHTML } from "shared/helpers"
import { useNewsPager } from "../api"

export function NewsDetailsPage() {
  const id = +useParams().id

  const { error, data: { meta, data } = {} } = useNewsPager(id)
  const { curent: news, ...pager } = data ?? {}
  useThrowError(error)
  const { title, description } = useSeo(meta?.seo)

  return (
    <div className="wrap">
      <motion.div
        initial={{ y: 40, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ default: { ease: "easeOut", duration: 1, delay: 1.2 } }}
      >
        <MetaTags title={title} description={description} />
        {news && (
          <div className="flex-col gap-[15px] md:flex md:flex-row md:gap-[20px]">
            {news.pictureUrl && (
              <div className="flex-1 max-md:hidden">
                <HeroImage src={news.pictureUrl} />
                {news.files.length > 0 && (
                  <div className="mt-[8px] flex md:mt-[20px]">
                    <div className="flex-auto">
                      <Button variant="middle" onClick={() => {}}>
                        Галерея
                      </Button>
                    </div>
                    <div className="flex-none">
                      <div className="aspect-1 h-[140px] overflow-hidden rounded-[20px] md:aspect-[218/140] xl:aspect-[328/170] xl:h-[170px]">
                        <img src={news.files[0].url} className="block h-[100%] w-[100%] object-cover" alt="" />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
            <div className="flex-1">
              <div className="md:pl-[20%]">
                <div className="text2 text-gray-500">{format(parseISO(news.publishedAt), "dd/MM/yyyy г.")}</div>
                <h2 className="h2 mt-[10px] md:mt-[20px]">{news.name}</h2>
                <div
                  className="text1 mt-[20px] text-gray-500 md:mt-[30px]"
                  dangerouslySetInnerHTML={{ __html: sanitizeHTML(news.body) }}
                />
                {news.pictureUrl && (
                  <div className="mt-[30px] flex-1 md:hidden">
                    <HeroImage src={news.pictureUrl} />
                    {news.files.length > 0 && (
                      <div className="mt-[8px] flex md:mt-[20px]">
                        <div className="flex-auto">
                          <Button variant="secondary" size="large" onClick={() => {}}>
                            Галерея
                          </Button>
                        </div>
                        <div className="flex-none">
                          <div className="aspect-[1] h-[140px] overflow-hidden rounded-[20px]">
                            <img src={news.files[0].url} className="щи block h-[100%] w-[100%] object-cover" alt="" />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
                <div className="mt-[30px] flex items-center gap-[20px] md:mt-[60px] xl:mt-[76px]">
                  <div className="flex-1 max-md:hidden" />
                  <div className="flex flex-auto justify-center justify-self-end md:flex-1">
                    <Pager
                      page={pager.page}
                      count={pager.count}
                      prev={pager.prev}
                      next={pager.next}
                      getUrl={({ id }) => `/news/${id}`}
                    />
                  </div>
                  <div className="flex flex-none justify-end md:flex-1">
                    <Link to="/news">
                      <Button>все новости</Button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </motion.div>
      <Cover />
    </div>
  )
}
