import { ReactComponent as BurgerIcon } from "../assets/burger.svg"
import { Link } from "react-router-dom"
import { ReactComponent as LogoIcon } from "../assets/logo.svg"
import { Menu } from "./menu"
import { ReactComponent as StroyIcon } from "../assets/stroy.svg"
import { cx } from "shared/helpers"
import { useCallback } from "react"
import { useContacts } from "shared/store"
import { useModal } from "shared/store"

export function HeaderMd({ variant }) {
  const { phone } = useContacts()
  const { openModal, modalIsOpen, closeModal } = useModal()

  const toggleMenu = useCallback(() => {
    if (modalIsOpen) closeModal()
    else openModal(<Menu onClose={closeModal} />)
  }, [closeModal, openModal, modalIsOpen])

  return (
    <div
      className={cx("flex-none bg-white max-md:z-[10]", {
        "bg-[transparent] text-white": variant === "overlay"
      })}
    >
      <div className="flex h-[var(--header-height-md)] items-center px-[100px] py-[20px]">
        <div className="flex flex-1 items-center">
          <div className="max-w-[350px] flex-1">
            <Link to="/">
              <LogoIcon className="block h-[32px] w-[99px] xl:h-[49px] xl:w-[154px]" />
            </Link>
          </div>
          <div className="flex-1 pt-[8px]">
            <Link to="/floor/1/1">
              <div className="h3 group">
                <span className="group-hover:text-pink-100">Выбрать</span> квартиру
              </div>
            </Link>
          </div>
        </div>
        <div className="flex min-w-[16%] flex-none justify-center">
          <div className="cursor-pointer p-[10px] transition hover:text-pink-100" onClick={toggleMenu}>
            <BurgerIcon />
          </div>
        </div>
        <div className="flex flex-1 items-baseline justify-end gap-[67px]">
          <Link to="/company">
            <div className="h4 group flex items-baseline justify-end gap-[7px]">
              <div className="transition group-hover:text-pink-100">
                <StroyIcon />
              </div>
              <div>стройфедерация</div>
            </div>
          </Link>
          <div className="h4 group flex items-baseline justify-end gap-[7px]">
            <a href="https://zakupki.stroifed.ru" target="_blank" rel="noreferrer">
              <div>тендеры</div>
            </a>
          </div>
          <div>
            <a href={`tel:${phone}`}>
              <div className="h4">
                <span className="transition group-hover:text-pink-100">+</span>
                {phone.slice(1)}
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
