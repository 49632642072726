const URL = "https://itef.ru"
export function Itef() {
  return (
    <a href={URL} target="_blank" rel="noreferrer">
      <div className="text-[12px] font-normal uppercase leading-[15px] text-gray-500">
        сайт от <span className="font-medium text-gray-100">ITEF</span>
      </div>
    </a>
  )
}
