import "swiper/css"
import "swiper/css/autoplay"
import "swiper/css/effect-fade"
import "swiper/css/navigation"

import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Autoplay, EffectFade, Navigation } from "swiper"

import { ReactComponent as ArrowNextIcon } from "../assets/arrow-next.svg"
import { ReactComponent as ArrowPrevIcon } from "../assets/arrow-prev.svg"
import { IconButton } from "shared/components/navigation"
import { motion } from "framer-motion"
import { useBanners } from "../api"
import { useState } from "react"
import { useThrowError } from "shared/hooks"

SwiperCore.use([Autoplay, EffectFade, Navigation])

export function Hero() {
  const { error, data: { data: banners = [] } = {} } = useBanners()
  useThrowError(error)
  const [active, setActive] = useState(0)

  const navigation = {
    prevEl: ".swiper-prev",
    nextEl: ".swiper-next"
  }

  return (
    <>
      <div className="relative h-[100vh]">
        <Swiper
          onSlideChange={swiper => setActive(swiper.realIndex)}
          loop={true}
          slidesPerView={1}
          effect="fade"
          className="h-[100%]"
          speed={1000}
          navigation={navigation}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false
          }}
        >
          {banners.map(banner => (
            <SwiperSlide key={banner.id}>
              <div className="relative h-[100%]">
                <div className="h-[100%]">
                  <img
                    src={banner.pictureUrl}
                    className="block h-[100%] w-[100%] object-cover"
                    fetchpriority="high"
                    alt={banner.name}
                  />
                </div>
                <div className="absolute top-0 left-0 right-0 bottom-0 flex items-center p-[100px]">
                  <motion.div
                    initial={{ y: 40, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ default: { ease: "easeOut", duration: 1.2, delay: 1.2 } }}
                    className="w-[100%]"
                  >
                    <div className="flex w-[100%] items-center">
                      <div className="flex-auto">
                        <div
                          className="h1 whitespace-pre font-medium text-white"
                          dangerouslySetInnerHTML={{ __html: banner.name }}
                        />
                        <div className="mt-[20px] text-[14px] leading-[1.55] text-white xl:text-[18px]">
                          {banner.subName}
                        </div>
                      </div>
                      {banner.link && (
                        <div className="flex-none">
                          <a href={banner.link} target={banner.linkTarget} rel="noreferrer" className="group">
                            <IconButton icon="arrowUp" />
                          </a>
                        </div>
                      )}
                    </div>
                  </motion.div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="absolute bottom-[100px] left-[100px] z-[9]">
          <div className="text-right text-[14px] leading-[1.55] xl:text-[18px]">
            {active + 1}/{banners.length}
          </div>
          <div className="mt-[10px] flex gap-[14px]">
            <div className="swiper-prev cursor-pointer">
              <ArrowPrevIcon />
            </div>
            <div className="swiper-next cursor-pointer">
              <ArrowNextIcon />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
