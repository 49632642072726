export const projects = [
  {
    id: "ibrag",
    name: "Умный дом",
    address: "на Гали Ибрагимова",
    square: "51 000 м<sup>2</sup>",
    parking: "общедоступный 7-этажный паркинг",
    advantages: [
      {
        id: 0,
        text: "Общедоступный 7-этажный паркинг для жителей"
      },
      {
        id: 1,
        text: "кондиционер и теплый пол в санузлах в каждой квартире"
      },
      {
        id: 2,
        text: "Тренажерный зал и митинг-румы для жителей"
      },
      {
        id: 3,
        text: "панорамное остекление"
      },
      {
        id: 4,
        text: "Благоустроенный двор"
      }
    ]
  },
  {
    id: "bakalin64",
    name: "Умный дом",
    address: "Бакалинская 64/4",
    square: "13 000 м<sup>2</sup>",
    advantages: [
      {
        id: 0,
        text: "кондиционер и теплый пол в санузлах в каждой квартире"
      },
      {
        id: 1,
        text: "панорамное остекление от пола до потолка"
      }
    ]
  },
  {
    id: "nabakalin",
    name: "Умный дом",
    address: "на Бакалинской",
    square: "36 000 м<sup>2</sup>",
    parking: "общедоступный 6-этажный паркинг",
    advantages: [
      {
        id: 0,
        text: "Все квартиры с чистовой отделкой"
      },
      {
        id: 1,
        text: "кондиционер и теплый пол в санузлах в каждой квартире"
      },
      {
        id: 2,
        text: "Планшет в каждой квартире"
      },
      {
        id: 3,
        text: "Благоустроенный двор"
      }
    ]
  }
]
