export const parking = [
  {
    id: 0,
    name: "открытая парковка",
    text: <>Жители и их гости будут иметь возможность парковаться на выделенной открытой парковке. </>
  },
  {
    id: 1,
    name: "подземный паркинг",
    text: <>Тёплый паркинг, в который можно спуститься на лифте.</>
  }
]
