import { cx } from "shared/helpers"

export function SubmitSuccess({ message = "ваша заявка принята", onResetClick, variant }) {
  return (
    <div className="grid max-md:text-center">
      <div
        className={cx("h1", {
          h2: variant === "landing"
        })}
      >
        спасибо!
      </div>
      <div
        className={cx("h1 mt-[20px]", {
          "h2 mt-[10px]": variant === "landing"
        })}
      >
        {message}
      </div>
      <div className="text1 mt-[10px] md:mt-[15px] xl:mt-[20px]">Мы свяжемся с вами в ближайшее время</div>
      {onResetClick && <button onClick={onResetClick}>Отправить еще</button>}
    </div>
  )
}
