import { NumericFormat } from "react-number-format"
import { PLACEMENT_STATUS } from "../constans"
import { Status } from "./status"
import { cx } from "shared/helpers"
import { declOfNum } from "shared/helpers"

export function PlacementTooltipLight({ placement = {}, children }) {
  const { placementNumber, status, rooms, square, apartmentCost, levels } = placement

  return (
    <div className="flex items-center gap-[5px]">
      <div className="grid flex-auto gap-[10px]">
        <Status statusId={status} />
        {placementNumber && (
          <div className="flex items-baseline gap-[10px]">
            <div className="font-comfortaa text-[20px] font-medium font-semibold leading-[1]">{placementNumber}</div>
            <Label>№ квартиры</Label>
          </div>
        )}
        <div className={cx("grid gap-[10px]", { "text-gray-300": status === PLACEMENT_STATUS.Sold })}>
          {rooms > 0 && (
            <Row>
              <Value>{rooms}</Value>
              <Label>
                {declOfNum(rooms, ["Комната", "Комнаты", "Комнат"])}
                {levels && (
                  <>
                    {" "}
                    -<span className="ml-[10px] inline-block text-pink-100">двухуровневая</span>
                  </>
                )}
              </Label>
            </Row>
          )}
          {square && (
            <Row>
              <Value>{square}</Value>
              <Label>
                м<sup>2</sup>
              </Label>
            </Row>
          )}
          {apartmentCost && (
            <Row>
              <Value>
                <NumericFormat value={apartmentCost} displayType={"text"} thousandSeparator={" "} />
              </Value>
              <Label>₽</Label>
            </Row>
          )}
        </div>
      </div>
      {children && <div className="flex-none">{children}</div>}
    </div>
  )
}

const Value = ({ children }) => {
  return <div className="font-comfortaa text-[16px]">{children}</div>
}

const Label = ({ children }) => {
  return <div className="text-[12px] font-medium">{children}</div>
}

const Row = ({ children, status }) => <div className="flex items-baseline gap-[10px]">{children}</div>
