import "swiper/css"
import "swiper/css/autoplay"

import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Autoplay, Navigation } from "swiper"
import { useCallback, useEffect, useRef, useState } from "react"

import { ReactComponent as ArrowNextIcon } from "../assets/arrow-next.svg"
import { ReactComponent as ArrowPrevIcon } from "../assets/arrow-prev.svg"
import { useDomEvent } from "shared/hooks"

SwiperCore.use([Autoplay, Navigation])

export function ApartmentsSwiper({ data }) {
  const $container = useRef()

  const [active, setActive] = useState(0)
  const [swiperHeight, setSwiperHeight] = useState()

  const updateSwiperHeight = useCallback(() => {
    if ($container.current) {
      const height = $container.current.offsetHeight
      setSwiperHeight(height)
    }
  }, [setSwiperHeight])

  const onSlideChange = useCallback(
    swiper => {
      setActive(swiper.realIndex)
    },
    [setActive]
  )

  useDomEvent("resize", updateSwiperHeight)
  useEffect(updateSwiperHeight, [updateSwiperHeight])

  return (
    <div className="relative w-[100%] md:h-[254px] xl:h-[387px]" ref={$container}>
      <div className="h-[100%] w-[100%] overflow-hidden rounded-[20px]">
        {swiperHeight && (
          <Swiper
            onSlideChange={onSlideChange}
            loop={true}
            speed={1000}
            spaceBetween={40}
            slidesPerView={"auto"}
            style={{ height: swiperHeight }}
            navigation={{
              prevEl: ".swiper-prev",
              nextEl: ".swiper-next"
            }}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false
            }}
          >
            {data.map(image => (
              <SwiperSlide key={image.id} style={{ height: "100%", width: "auto" }}>
                <img loading="lazy" alt="" className="block h-[100%] rounded-[20px]" src={image.src} />
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </div>
      <div className="absolute top-[-52px] right-0 flex items-center gap-[20px]">
        <div className="mt-[8px] text-right text-[12px] leading-[1.55] text-gray-500 md:text-[14px] xl:text-[18px]">
          {active + 1}/{data.length}
        </div>
        <div className="mt-[6px] flex gap-[14px]">
          <div className="swiper-prev cursor-pointer">
            <ArrowPrevIcon />
          </div>
          <div className="swiper-next cursor-pointer">
            <ArrowNextIcon />
          </div>
        </div>
      </div>
    </div>
  )
}
