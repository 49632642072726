import { Button } from "shared/components/navigation"
import { IconButton } from "shared/components/navigation"
import { Link } from "react-router-dom"
import { SwiperSm } from "./swiper-sm"

export function Plans({ swiper }) {
  return (
    <div className="mt-[60px] md:mt-[120px] xl:mt-[150px]">
      <div className="text2 flex-none text-gray-500">Планировки</div>
      <div className="relative mt-[15px] md:mt-[20px]">
        <img alt="" className="block max-md:hidden" src={require("../assets/p1.jpg")} />
        <img alt="" className="block md:hidden" src={require("../assets/pm1.jpg")} />
        <div className="md:grid-10 absolute top-0 bottom-0 right-0 left-0 items-center max-md:right-[20px] max-md:left-[auto] max-md:flex">
          <div className="col-start-8 col-end-11">
            <Link to="/floor/1/1">
              <Button>выбрать квартиру</Button>
            </Link>
          </div>
        </div>
      </div>
      <div className="md:grid-10 mt-[60px] md:mt-[120px] xl:mt-[150px]">
        <div className="col-start-1 col-end-3 max-md:hidden">
          <ReportLink>
            <Image src={require("../assets/p2.jpg")} />
          </ReportLink>
        </div>
        <div className="col-start-3 col-end-5 max-md:hidden">
          <ReportLink>
            <Image src={require("../assets/p3.jpg")} />
          </ReportLink>
        </div>
        <div className="col-start-6 col-end-8 flex-col gap-[40px] md:flex">
          <div className="flex-auto">
            <div className="text2 flex-none text-gray-500">Ход строительства</div>
            <div className="mt-[15px] flex gap-[40px] md:mt-[25px] md:items-center">
              <div className="h2 flex-auto">Фотоотчёт</div>
              <div className="group flex-none">
                <ReportLink>
                  <IconButton icon="arrowUp" />
                </ReportLink>
              </div>
            </div>
          </div>
          <div className="mt-[20px] overflow-y-hidden md:hidden">
            <SwiperSm data={swiper} />
          </div>
          <div className="flex-none max-md:mt-[30px]">
            <a
              href="https://ucams.ufanet.ru/api/internal/embed/1653372953YDQ270/?ttl=3600&autoplay=true&mute=true"
              target="_blank"
              rel="noreferrer"
            >
              <Button variant="secondary">Онлайн -камера</Button>
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

const ReportLink = ({ children }) => {
  const photoReportLink =
    "https://xn--80az8a.xn--d1aqf.xn--p1ai/%D1%81%D0%B5%D1%80%D0%B2%D0%B8%D1%81%D1%8B/%D0%BA%D0%B0%D1%82%D0%B0%D0%BB%D0%BE%D0%B3-%D0%BD%D0%BE%D0%B2%D0%BE%D1%81%D1%82%D1%80%D0%BE%D0%B5%D0%BA/%D0%BE%D0%B1%D1%8A%D0%B5%D0%BA%D1%82/43319"
  return (
    <a target="_blank" rel="noreferrer" href={photoReportLink} className="group block">
      {children}
    </a>
  )
}

const Image = ({ src }) => (
  <div className="relative overflow-hidden rounded-[20px]">
    <img loading="lazy" alt="" className="block" src={src} />
    <div className="absolute top-0 right-0 left-0 bottom-0 bg-black opacity-[0.15] transition group-hover:opacity-0" />
  </div>
)
