import "swiper/css"
import "swiper/css/autoplay"

import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Autoplay } from "swiper"
import { CompanyFace } from "./company-face"

SwiperCore.use([Autoplay])

export function CompanySwiper({ data, onSwiper = () => {} }) {
  return (
    <div className="w-[100%] overflow-hidden">
      <Swiper
        onSwiper={onSwiper}
        loop={false}
        speed={1000}
        spaceBetween={8}
        slidesPerView={"auto"}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false
        }}
      >
        {data.map(item => (
          <SwiperSlide key={item.id} style={{ width: 217 }}>
            <CompanyFace face={item} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}
