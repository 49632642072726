import { ReactComponent as CloseIcon } from "../assets/close.svg"
import { cx } from "shared/helpers"

export function Modal({ children, onClose, variant }) {
  return (
    <div className="gap-[50px] px-[16px] pt-[70px] pb-[20px] md:flex md:h-[100%] md:flex-col md:gap-[40px] md:py-[30px] md:px-[100px] xl:gap-[47px] xl:py-[40px]">
      <div className="z-[2] flex flex-none cursor-pointer justify-center max-md:hidden">
        <div
          className={cx({
            "flex h-[50px] w-[50px] items-center justify-center rounded-[50%] bg-white/50 transition hover:bg-white/70 md:h-[64px] md:w-[64px]":
              variant === "fullscreen"
          })}
        >
          <CloseIcon className="block h-[20px] w-[20px] md:h-[24px] md:w-[24px]" onClick={onClose} />
        </div>
      </div>
      <div className="z-[1] flex-auto">{children}</div>
    </div>
  )
}
