import { ReactComponent as ArrowNextIcon } from "../assets/arrow-next.svg"
import { ReactComponent as ArrowPrevIcon } from "../assets/arrow-prev.svg"
import { Link } from "react-router-dom"
import { cx } from "shared/helpers"

export function Pager({ page, count, prev, next, getUrl }) {
  return (
    <div className="text-right">
      {count > 0 && (
        <div className="text1">
          {page}/{count}
        </div>
      )}
      <div className="align-center mt-[6px] flex gap-[14px] md:mt-[10px]">
        {prev ? (
          <Link to={getUrl(prev)}>
            <ArrowPrevIcon />
          </Link>
        ) : (
          <span className={cx({ "opacity-5": page <= 1 })}>
            <ArrowPrevIcon />
          </span>
        )}
        {next ? (
          <Link to={getUrl(next)}>
            <ArrowNextIcon />
          </Link>
        ) : (
          <span className={cx({ "opacity-5": page >= count })}>
            <ArrowNextIcon />
          </span>
        )}
      </div>
    </div>
  )
}
