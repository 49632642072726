export const apartmentsSwiper = [
  {
    id: 0,
    src: require("../assets/aps1.jpg"),
    srcSm: require("../assets/apsm1.jpg")
  },
  {
    id: 1,
    src: require("../assets/aps2.jpg"),
    srcSm: require("../assets/apsm2.jpg")
  },
  {
    id: 2,
    src: require("../assets/aps3.jpg"),
    srcSm: require("../assets/apsm3.jpg")
  },
  {
    id: 3,
    src: require("../assets/aps4.jpg"),
    srcSm: require("../assets/apsm4.jpg")
  }
]
