import { Collapse } from "./collapse"

export function Parking({ data }) {
  return (
    <div className="mt-[60px] md:mt-[120px] xl:mt-[140px]">
      <div className="md:grid-10">
        <div className="col-start-1 col-end-7 max-md:hidden">
          <img loading="lazy" className="block" alt="Паркинг" src={require("../assets/parking.jpg")} />
        </div>
        <div className="col-start-8 col-end-11">
          <div className="text2 flex-none text-gray-500">Паркинг</div>
          <div className="mt-[15px] md:hidden">
            <img loading="lazy" className="block" alt="Паркинг" src={require("../assets/parkingm.jpg")} />
          </div>
          <div className="h2 mt-[20px]">
            удобство
            <br /> автовладельцев
          </div>
          <div className="mt-[20px] xl:mt-[40px]">
            <Collapse data={data} />
          </div>
        </div>
      </div>
    </div>
  )
}
