import { ReactComponent as ArrowDownIcon } from "../assets/arrow-down.svg"
import { ReactComponent as ArrowUpIcon } from "../assets/arrow-up.svg"
import { Link } from "react-router-dom"
import { cx } from "shared/helpers"
import { useRef } from "react"

export function FloorPicker({ floors, currentFloorNumber, getFloorUrl }) {
  const container = useRef()

  const scroll = direction => {
    if (container.current) {
      container.current.scrollTo({
        top: container.current.scrollTop - direction * DELTA,
        behavior: "smooth"
      })
    }
  }

  const scrollUp = () => scroll(1)
  const scrollDown = () => scroll(-1)

  return (
    <div className="h-[100%] w-[80px] overflow-hidden">
      <div className="flex h-[100%] w-[97px] flex-col gap-[20px] overflow-hidden">
        <div className="flex-none">
          <div className="flex w-[40px] cursor-pointer justify-center" onClick={scrollUp}>
            <ArrowUpIcon className="block" />
          </div>
        </div>
        <div className="flex-auto overflow-y-auto" ref={container}>
          <div className="h-100% flex flex-col-reverse gap-[10px] overflow-hidden">
            {floors.map(floor => {
              const floorNumber = floor.floor
              const href = getFloorUrl(floorNumber)
              const countLabel = floor.availableFlats || "-"
              const isSelected = floorNumber === currentFloorNumber

              return (
                <Link to={href} key={floorNumber} className="group flex items-center">
                  <div
                    className={cx(
                      "flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-[50%] bg-gray-600 text-[18px] font-semibold text-gray-500 transition group-hover:text-pink-100",
                      { "bg-pink-100 text-white group-hover:text-white": isSelected }
                    )}
                  >
                    {floorNumber}
                  </div>
                  <div
                    className={cx(
                      "flex h-[40px] w-[40px] items-center justify-center text-[14px] font-medium text-gray-200 group-hover:text-gray-100",
                      { "text-gray-100": isSelected }
                    )}
                  >
                    {countLabel}
                  </div>
                </Link>
              )
            })}
          </div>
        </div>
        <div className="flex-none">
          <div className="flex w-[40px] cursor-pointer justify-center" onClick={scrollDown}>
            <ArrowDownIcon className="block" />
          </div>
        </div>
      </div>
    </div>
  )
}

const DELTA = 50
