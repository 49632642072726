import { IconButton } from "shared/components/navigation"
import { Link } from "react-router-dom"

export function Finish() {
  return (
    <Link to="/floor/1/1" className="mt-[60px] block md:mt-[120px] xl:mt-[150px]">
      <div className="group relative">
        <img loading="lazy" alt="" className="block w-[100%] max-md:hidden" src={require("../assets/f.jpg")} />
        <img loading="lazy" alt="" className="block w-[100%] md:hidden" src={require("../assets/fm.jpg")} />
        <div className="absolute bottom-[13px] left-[13px] right-[40px] md:bottom-[40px] md:left-[40px] md:flex">
          <div className="h7 md:h2 flex-auto text-white">
            Уникальные форматы
            <br className="max-md:hidden" /> планировок на любой вкус
          </div>
          <div className="flex-none max-md:hidden">
            <IconButton icon="arrowUp" />
          </div>
        </div>
        <div className="absolute top-[16px] right-[16px] md:hidden">
          <IconButton icon="arrowUp" />
        </div>
      </div>
    </Link>
  )
}
