import { Button } from "shared/components/navigation"
import { Gallery } from "shared/components/gallery"
import { Modal } from "shared/components/modal"
import { useGallery } from "../api"
import { useModal } from "shared/store"
import { useThrowError } from "shared/hooks"

export function CompanyProject({ project }) {
  const { openModal, closeModal } = useModal()
  const { error, data: { data: projectData = {} } = {} } = useGallery(project.id)
  useThrowError(error)
  const files = projectData.files

  const GalleryModal = () => (
    <Modal onClose={closeModal} variant="fullscreen">
      <Gallery files={files} fullscreen={true} autoplay={true} />
    </Modal>
  )

  const openGallery = () => {
    openModal(<GalleryModal />)
  }

  return (
    <div className="md:grid-10">
      <div className="text1 col-start-1 col-end-4">
        <div className="h1">Умный дом</div>
        <div className="h2 mt-[10px] md:mt-[20px]">{projectData.name}</div>
        <div
          className="h7 mt-[20px] lowercase md:mt-[40px] xl:mt-[60px]"
          dangerouslySetInnerHTML={{ __html: project.square }}
        />
        {project.parking && <div className="h7 mt-[10px] md:mt-[16px] xl:mt-[20px]">{project.parking}</div>}
        <div className="max-md:hidden md:mt-[60px]">
          <Button variant="secondary" size="middle" onClick={openGallery}>
            галерея
          </Button>
        </div>
      </div>
      <div className="text1 col-start-4 col-end-8 max-md:mt-[40px]">
        <div className="md:pr-[17%]">
          {projectData.coverUrl && (
            <div className="aspect-[288/162] overflow-hidden rounded-[20px] md:aspect-[380/316] xl:aspect-[558/468]">
              <img loading="lazy" alt="" className="block h-[100%] w-[100%] object-cover" src={projectData.coverUrl} />
            </div>
          )}
          {projectData.files && (
            <div className="mt-[8px] grid grid-cols-2 gap-[8px] md:mt-[20px] md:gap-[20px]">
              {projectData.files[0] && (
                <div className="aspect-[140/87] md:aspect-[180/112] xl:aspect-[268/170]">
                  <img
                    loading="lazy"
                    alt=""
                    className="block h-[100%] w-[100%] overflow-hidden rounded-[20px] object-cover"
                    src={projectData.files[0].url}
                  />
                </div>
              )}
              {projectData.files[1] && (
                <div className="aspect-[140/87] md:aspect-[180/112] xl:aspect-[268/170]">
                  <img
                    loading="lazy"
                    alt=""
                    className="block h-[100%] w-[100%] overflow-hidden rounded-[20px] object-cover"
                    src={projectData.files[1].url}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="mt-[30px] flex justify-center md:hidden">
        <Button variant="secondary" size="middle" onClick={openGallery}>
          галерея
        </Button>
      </div>
      <div className="col-start-8 col-end-11 max-md:mt-[30px]">
        <div className="text1">{projectData.description}</div>
        <div className="border-1 mt-[30px] border-t border-gray-300 md:mt-[40px] xl:mt-[60px]">
          {project.advantages.map(advantage => (
            <div key={advantage.id} className="border-1 h8 border-b border-gray-300 py-[15px] xl:py-[20px]">
              {advantage.text}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
