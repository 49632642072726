import { Button } from "shared/components/navigation"
import { Itef } from "shared/components/itef"
import { Link } from "react-router-dom"
import { ReactComponent as LogoIcon } from "../assets/logo.svg"
import { ReactComponent as VkIcon } from "../assets/vk.svg"
import { ReactComponent as YoutubeIcon } from "../assets/youtube.svg"
import { sanitizeHTML } from "shared/helpers"
import { useContacts } from "shared/store"

export function FooterMd() {
  const { phone, address, social } = useContacts()
  const { vk, you } = social

  return (
    <div className="border-t-[1px] border-gray-600 bg-white px-[100px] pt-[55px] pb-[20px] xl:pt-[73px]">
      <div className="grid-10">
        <div className="col-start-2 col-end-4">
          <div className="grid gap-[7px]">
            <div>
              <FooterLink to="/">о проекте</FooterLink>
            </div>
            <div>
              <FooterLink to="/company">о компании</FooterLink>
            </div>
            <div>
              <FooterLink to="/floor/1/1">Выбрать квартиру</FooterLink>
            </div>
            <div>
              <FooterLink to="/news">новости</FooterLink>
            </div>
            <div>
              <FooterLink to="/contacts">контакты</FooterLink>
            </div>
          </div>
        </div>
        <div className="col-start-4 col-end-6">
          <div className="grid gap-[7px]">
            <div>
              <FooterLinkBlank href="https://zakupki.stroifed.ru/">Тендеры</FooterLinkBlank>
            </div>
            <div>
              <FooterLinkBlank href="https://наш.дом.рф/сервисы/каталог-новостроек/объект/43319">
                проектная декларация
              </FooterLinkBlank>
            </div>
            <div>
              <FooterLinkBlank href="https://наш.дом.рф/сервисы/каталог-новостроек/объект/43319">
                Ход строительства
              </FooterLinkBlank>
            </div>
            <div>
              <FooterLinkBlank href="https://ucams.ufanet.ru/api/internal/embed/1653372953YDQ270/?ttl=3600&autoplay=true&mute=true">
                Онлайн-камера
              </FooterLinkBlank>
            </div>
            <div>
              <FooterLinkBlank href={`${process.env.PUBLIC_URL}/docs/politics.pdf`}>
                политика конфиденциальности
              </FooterLinkBlank>
            </div>
          </div>
        </div>
        <div className="col-start-6 col-end-8 max-xl:hidden">
          <div className="flex justify-end gap-[7px]">
            {vk && (
              <div className="flex-none">
                <a href={vk} target="_blank" rel="noreferrer">
                  <Button variant="secondary">
                    <VkIcon />
                  </Button>
                </a>
              </div>
            )}
            {you && (
              <div className="flex-none">
                <a href={you} target="_blank" rel="noreferrer">
                  <Button variant="gray" size="small">
                    <YoutubeIcon />
                  </Button>
                </a>
              </div>
            )}
          </div>
        </div>
        <div className="col-start-8 col-end-11 max-xl:hidden">
          <div className="flex justify-end xl:mt-[7px]">
            <Itef />
          </div>
          <div className="mt-[120px] flex flex-col justify-end">
            <a href={`tel:${phone}`}>
              <div className="h1">{phone}</div>
            </a>
            <div
              className="h6 mt-[30px] whitespace-pre-line"
              dangerouslySetInnerHTML={{ __html: sanitizeHTML(address.complex) }}
            />
          </div>
        </div>
        <div className="col-start-7 col-end-11 xl:hidden">
          <div className="flex gap-[7px]">
            {vk && (
              <div className="flex-none pt-[10px]">
                <a href={vk} target="_blank" rel="noreferrer">
                  <Button variant="secondary">
                    <VkIcon />
                  </Button>
                </a>
              </div>
            )}
            {you && (
              <div className="flex-none">
                <a href={you} target="_blank" rel="noreferrer">
                  <Button variant="gray" size="small">
                    <YoutubeIcon />
                  </Button>
                </a>
              </div>
            )}
            <div className="flex flex-auto justify-end">
              <Itef />
            </div>
          </div>
          <div className="mt-[17px] text-right">
            <a href={`tel:${phone}`}>
              <div className="h1">{phone}</div>
            </a>
            <div
              className="h6 mt-[10px] whitespace-pre-line"
              dangerouslySetInnerHTML={{ __html: sanitizeHTML(address.complex) }}
            />
          </div>
        </div>
      </div>
      <div className="mt-[-10px] xl:mt-[22px]">
        <Link to="/">
          <LogoIcon className="block h-[32px] w-[99px] xl:h-[49px] xl:w-[154px]" />
        </Link>
        <div className="text3 mt-[10px]">
          Любая информация, представленная на данном сайте, носит исключительно информационный характер и ни при каких
          условиях не является публичной офертой, определяемой положениями статьи 437 ГК РФ.
        </div>
      </div>
    </div>
  )
}

const FooterLink = ({ to, children }) => {
  return (
    <Link to={to} className="h5 hover:text-pink-100">
      {children}
    </Link>
  )
}

const FooterLinkBlank = ({ href, children }) => (
  <a href={href} target="_blank" rel="noreferrer" className="h5 hover:text-pink-100">
    {children}
  </a>
)
