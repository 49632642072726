import { Collapse } from "./collapse"
import { Form } from "./form"
import { SwiperSm } from "./swiper-sm"

export function Infrastructure({ data, swiper }) {
  return (
    <div className="mt-[60px] md:mt-[120px] xl:mt-[150px]">
      <div className="text2 flex-none text-gray-500">Инфраструктура комфорта</div>
      <div className="h2 mt-[15px] md:mt-[20px]">
        Мы позаботились о том,
        <br /> чтобы у вас не было забот
      </div>
      <div className="md:grid-10 mt-[20px] md:mt-[40px]">
        <div className="col-start-1 col-end-5 max-md:hidden">
          <div className="flex gap-[20px]">
            <div>
              <img loading="lazy" src={require("../assets/i1.jpg")} alt="" className="block" />
            </div>
            <div>
              <img loading="lazy" src={require("../assets/i2.jpg")} alt="" className="block" />
            </div>
          </div>
        </div>
        <div className="mt-[20px] h-[162px] overflow-y-hidden md:hidden">
          <SwiperSm data={swiper} />
        </div>
        <div className="col-start-6 col-end-9 max-md:mt-[30px]">
          <Collapse data={data} />
        </div>
      </div>
      <Form requestType="excursion">
        <div className="h2">
          Запишитесь на&nbsp;экскурсию
          <br className="md:hidden" /> в&nbsp;наши Умные&nbsp;дома
        </div>
      </Form>
    </div>
  )
}
