import { BuildingTooltip } from "./building-tooltip"
import { Link } from "react-router-dom"
import Popup from "reactjs-popup"
import { PortalRenderer } from "./portal-renderer"
import { cx } from "shared/helpers"
import { motion } from "framer-motion"
import { selectBuildings } from "../selectors"
import { useGenplanSchema } from "../api"
import { useState } from "react"
import { useThrowError } from "shared/hooks"

export function GenplanSchema({ selectedBuildings = {}, scalable = true }) {
  const [hover, setHover] = useState(false)
  const { error, data: { data = {} } = {} } = useGenplanSchema()
  useThrowError(error)

  const buildings = selectBuildings(data?.buildings ?? []) || []

  const isSelected = ({ building, section }) => {
    if (!selectedBuildings[building.id]) return false
    return selectedBuildings[building.id].includes(+section.number)
  }

  const buildingsArray = buildings.filter(building => {
    return Object.keys(selectedBuildings).includes(building.id.toString())
  })

  const getSelectedColor = args => {
    return isSelected(args) ? "#ECD5B7" : "transparent"
  }

  const setHoverValue = value => {
    if (scalable) setHover(value)
  }

  return (
    <PortalRenderer active={hover}>
      {hover && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ default: { ease: "easeOut", delay: 0.1 } }}
          className="fixed top-0 left-0 right-0 bottom-0 bg-gray-100/60 max-md:hidden"
        />
      )}
      <div
        className={cx("relative origin-bottom-right cursor-pointer bg-white delay-100", {
          "md:transition md:hover:z-[10] md:hover:scale-[2]": scalable
        })}
        onMouseEnter={() => setHoverValue(true)}
        onMouseLeave={() => setHoverValue(false)}
      >
        <svg fill="none" width="100%" height="100%" viewBox="0 0 348 229">
          <image width="100%" height="100%" xlinkHref={require("../assets/genplan-scheme.svg").default} />
          {buildingsArray.map(building => {
            return (
              <g key={building.id}>
                {building.sections.map(section => {
                  if (section.isHidden) return null
                  return (
                    <Link key={section.id} to={`/floor/${building.id}/${section.number}`}>
                      <Popup
                        trigger={
                          <path
                            d={section.polyScheme}
                            stroke="#1C1C1C"
                            strokeWidth="0.5"
                            fill={getSelectedColor({ building, section })}
                            className={cx(
                              "cursor-pointer stroke-gray-100 transition hover:fill-blue-100 hover:opacity-20",
                              {
                                "fill-gray-300": section.flatsAvailable === 0,
                                "fill-blue-100": isSelected({ building, section })
                              }
                            )}
                          />
                        }
                        on={["hover"]}
                        position="top right"
                        arrow={false}
                      >
                        <BuildingTooltip status={building.status} flatsAvailable={building.flatsAvailable} />
                      </Popup>
                    </Link>
                  )
                })}
              </g>
            )
          })}
        </svg>
      </div>
    </PortalRenderer>
  )
}
