export const infrastructureSwiper = [
  {
    id: 0,
    src: require("../assets/im2.jpg")
  },
  {
    id: 1,
    src: require("../assets/im3.jpg")
  }
]
