export const security = [
  {
    id: 0,
    name: "камера видеонаблюдения",
    text: <>На территории всего комплекса установлены камеры видеонаблюдения.</>
  },
  {
    id: 1,
    name: "face ID",
    text: (
      <>Одной из функцией доступа в дом будет возможность подключить доступ через систему распознавания лиц Face-ID.</>
    )
  },
  {
    id: 2,
    name: "пожарная сигнализация",
    text: <>Помимо общедомовой противопожарной системы в каждой квартире будут установлены пожарные сигнализации.</>
  },
  {
    id: 3,
    name: "IP-Видеодомофон",
    text: <>В каждой квартире предусмотрен IP-видеодомофон.</>
  }
]
