export const innovation = [
  {
    id: 0,
    name: "безопасно",
    text: (
      <>
        Фотоэлектрическая фасадная система прошла комплексные испытания в семи независимых аккредитованных
        лабораториях.
        <br />
        <br />
        По результатам всех испытаний получены положитель-ные заключения, позволяющие применять фотоэлект-рическую
        фасадную систему в любых типах зданий:
        <ul className="list-disc pl-[20px]">
          <li>Испытания на безопасность электромагнитного излучения.</li>
          <li>Огневые испытания навесной фотоэлектрической фасадной системы.</li>
          <li>Испытания на определение несущей способности.</li>
          <li>Испытания на коррозионную стойкость и долговеч-ность материалов.</li>
          <li>Испытания на морозостойкость.</li>
          <li>Испытания на определение класса пожарной опасности.</li>
          <li>Санитарно-эпидемиологические испытания.</li>
        </ul>
        <br />
        Фотоэлектрическая фасадная система сертифицирована в соответствии с требованиями строительных, санитарных,
        пожарных, экологических, а также других норм безопасности, утвержденных действующим законодательством. Получено
        техническое свидетельство о пригодности применения в строительстве, выданное МинСтроем России.
      </>
    )
  }
]
