export function CompanyFace({ face }) {
  return (
    <div>
      <div className="aspect-square w-[100%] overflow-hidden rounded-[20px]">
        {face.pictureUrl && (
          <img
            loading="lazy"
            alt={face.fullName}
            src={face.pictureUrl}
            className="block h-[100%] w-[100%] object-cover"
          />
        )}
      </div>
      <div className="h7 mt-[20px] md:mt-[16px] xl:mt-[30px]">
        <div>{face.surname}</div>
        <div>
          {face.name} {face.patronymic}
        </div>
      </div>
      {face.position && <div className="text2 mt-[15px] text-gray-500 md:mt-[7px] xl:mt-[20px]">{face.position}</div>}
    </div>
  )
}
