import { About } from "../components/about"
import { Advantages } from "../components/advantages"
import { AdvantagesSm } from "../components/advantages-sm"
import { Apartments } from "../components/apartments"
import { Cover } from "shared/components/layout"
import { Developer } from "../components/developer"
import { Finish } from "../components/finish"
import { Hero } from "../components/hero"
import { HeroSm } from "../components/hero-sm"
import { Infrastructure } from "../components/infrastructure"
import { Innovation } from "../components/innovation"
import { Live } from "../components/live"
import { Location } from "../components/location"
import { MetaTags } from "shared/components/seo"
import { Pano } from "../components/pano"
import { Parking } from "../components/parking"
import { Plans } from "../components/plans"
import { Security } from "../components/security"
import { addMockProps } from "shared/helpers"
import { mock } from "../mock"
import { motion } from "framer-motion"
import { useSeo } from "shared/hooks"

export const LandingPage = addMockProps(function LandingPage({
  innovation,
  advantages,
  location,
  pano,
  live,
  liveSwiper,
  infrastructure,
  infrastructureSwiper,
  security,
  parking,
  apartments,
  apartmentsSwiper,
  plansSwiper
}) {
  const { title, description } = useSeo()

  return (
    <div>
      <MetaTags title={title} description={description} />
      <div className="relative -top-[89px] max-md:hidden">
        <Hero />
      </div>
      <div className="md:hidden">
        <HeroSm />
      </div>
      <div className="wrap pt-[20px] md:pt-[78px]">
        <AnimatedContainer>
          <About />
        </AnimatedContainer>
        <AnimatedContainer>
          <Developer />
        </AnimatedContainer>
        <AnimatedContainer>
          <Innovation data={innovation} />
        </AnimatedContainer>
        <AnimatedContainer>
          <div className="max-md:hidden">
            <Advantages advantages={advantages} />
          </div>
          <div className="md:hidden">
            <AdvantagesSm swiper={advantages} />
          </div>
        </AnimatedContainer>
        <AnimatedContainer>
          <Plans swiper={plansSwiper} />
        </AnimatedContainer>
        <AnimatedContainer>
          <Location data={location} />
        </AnimatedContainer>
        <AnimatedContainer>
          <Pano data={pano} />
        </AnimatedContainer>
        <AnimatedContainer>
          <Live data={live} swiper={liveSwiper} />
        </AnimatedContainer>
        <AnimatedContainer>
          <Infrastructure data={infrastructure} swiper={infrastructureSwiper} />
        </AnimatedContainer>
        <AnimatedContainer>
          <Security data={security} />
        </AnimatedContainer>
        <AnimatedContainer>
          <Parking data={parking} />
        </AnimatedContainer>
        <AnimatedContainer>
          <Apartments data={apartments} swiper={apartmentsSwiper} />
        </AnimatedContainer>
        <AnimatedContainer>
          <Finish />
        </AnimatedContainer>
        <Cover />
      </div>
    </div>
  )
},
mock)

const AnimatedContainer = ({ children }) => (
  <motion.div
    initial={{ y: 100, opacity: 0 }}
    whileInView={{ y: 0, opacity: 1 }}
    transition={{ default: { ease: "easeOut", duration: 0.6 } }}
  >
    {children}
  </motion.div>
)
