import { AnimatePresence, motion } from "framer-motion"
import { useRef, useState } from "react"

import { Link } from "react-router-dom"
import { cx } from "shared/helpers"
import { useOutsideClick } from "shared/hooks"

export function FloorPickerSm({ floors, currentFloorNumber, getFloorUrl }) {
  const [isOpened, setIsOpened] = useState()
  const ref = useRef()

  useOutsideClick(ref, () => {
    setIsOpened(false)
  })

  return (
    <div>
      <div
        className="flex w-[80px] items-center gap-[10px]"
        onClick={() => {
          setIsOpened(!isOpened)
        }}
      >
        <div className="text-[12px] font-medium">Этаж</div>
        <div className="flex h-[40px] w-[40px] items-center justify-center rounded-[50%] bg-pink-100 font-semibold text-white">
          {currentFloorNumber}
        </div>
      </div>
      <AnimatePresence mode="wait">
        {isOpened && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ default: { ease: "easeOut", duration: 0.5 } }}
            className="fixed top-0 left-0 right-0 bottom-0 z-[10] bg-gray-100/50"
          />
        )}
      </AnimatePresence>
      <AnimatePresence mode="wait">
        {isOpened && (
          <motion.div
            ref={ref}
            initial={{ height: 0 }}
            animate={{ height: "76vh" }}
            exit={{ height: 0 }}
            transition={{ default: { ease: "easeOut", duration: 0.5 } }}
            className="fixed bottom-0 left-0 right-0 z-[11] flex h-[76vh] flex-col rounded-t-[20px] bg-white py-[20px] px-[16px]"
          >
            <div className="flex flex-none items-center text-[12px] font-medium text-gray-700">
              <div className="w-[40px]">Этаж</div>
              <div className="w-[40px] text-center">Кв.</div>
            </div>
            <div className="mt-[10px] mb-[16px] h-[1px] w-[100%] flex-none bg-gray-400" />
            <div className="grid flex-auto gap-[10px] overflow-y-auto">
              {floors.map(floor => {
                const floorNumber = floor.floor
                const href = getFloorUrl(floorNumber)
                const countLabel = floor.availableFlats || "-"
                const isSelected = floorNumber === currentFloorNumber
                return (
                  <div
                    key={floorNumber}
                    onClick={() => {
                      setIsOpened(false)
                    }}
                  >
                    <Link to={href} className="group flex items-center">
                      <div
                        className={cx(
                          "flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-[50%] bg-gray-600 text-[18px] font-semibold text-gray-500 transition group-hover:text-pink-100",
                          { "bg-pink-100 text-white group-hover:text-white": isSelected }
                        )}
                      >
                        {floorNumber}
                      </div>
                      <div
                        className={cx(
                          "flex h-[40px] w-[40px] items-center justify-center text-[14px] font-medium text-gray-200 group-hover:text-gray-100",
                          { "text-gray-100": isSelected }
                        )}
                      >
                        {countLabel}
                      </div>
                    </Link>
                  </div>
                )
              })}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}
