import { ReactComponent as LoupeIcon } from "../assets/loupe.svg"
import { useState } from "react"

export function PlacementPlan({ planType = {}, onGalleryButtonClick = () => {}, showGalleryButton }) {
  const render = planType?.renderPlanUrl ?? planType?.renderSchemeUrl
  const renderMebel = planType?.renderViewUrl
  const [mebelMode, setMebelMode] = useState(true)

  return (
    <div
      className="realtive group cursor-pointer justify-center md:absolute md:top-0 md:bottom-0 md:right-0 md:left-0 md:flex"
      onClick={onGalleryButtonClick}
      onMouseEnter={() => {
        setMebelMode(false)
      }}
      onMouseLeave={() => {
        setMebelMode(true)
      }}
    >
      <div className="h-0 w-0 overflow-hidden">
        <img alt="" src={renderMebel} />
        <img alt="" src={render} />
      </div>
      <img
        alt=""
        src={mebelMode && renderMebel ? renderMebel : render}
        className="block h-[100%] w-[100%] object-contain"
      />
      {showGalleryButton && (
        <div
          className="absolute top-[50%] left-[50%] z-[2] flex
      h-[70px] w-[70px] translate-x-[-50%] translate-y-[-50%] items-center
      justify-center rounded-[50%] bg-white/50 text-pink-100 opacity-0 transition
      group-hover:opacity-100"
        >
          <LoupeIcon className="block h-[19px] w-[19px] md:h-[20px] md:w-[20px] xl:h-[26px] xl:w-[26px]" />
        </div>
      )}
    </div>
  )
}
