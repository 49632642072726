import { createPager, jsonSWR } from "shared/helpers"
import useSWR, { mutate } from "swr"

import { fetcher } from "shared/http"

export const useNews = () => useSWR("/news")

export const useNewsById = id => useSWR(() => "/news/" + id.toString())

export const useNewsPager = id => {
  const ids = (useNews().data?.data || []).map(({ id }) => id)
  if (ids.length > 0 && ids.indexOf(id) === -1) {
    throw Error("News ID not found")
  }

  const pager = createPager(id, ids)

  const { data: { data: prev } = {} } = useNewsById(pager.prev)
  const { data: { data: next } = {} } = useNewsById(pager.next)

  const res = useNewsById(pager.curent)
  const curent = res?.data?.data

  const page = pager.page
  const count = ids.length

  return jsonSWR(res, { prev, next, page, count, curent })
}

export const prefetchNewsById = id => {
  const key = `/news/${id}`
  mutate(key, fetcher(key))
}
