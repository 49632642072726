import { Button } from "shared/components/navigation"
import { Itef } from "shared/components/itef"
import { Link } from "react-router-dom"
import { ReactComponent as LogoIcon } from "../assets/logo.svg"
import { ReactComponent as VkIcon } from "../assets/vk.svg"
import { ReactComponent as YoutubeIcon } from "../assets/youtube.svg"
import { sanitizeHTML } from "shared/helpers"
import { useContacts } from "shared/store"

export function FooterSm() {
  const { phone, address, social } = useContacts()
  const { vk, you } = social

  return (
    <div className="border-t-[1px] border-gray-600 bg-white px-[16px] py-[20px]">
      <div className="grid gap-[10px]">
        <div>
          <FooterLink to="/">о проекте</FooterLink>
        </div>
        <div>
          <FooterLink to="/company">о компании</FooterLink>
        </div>
        <div>
          <FooterLink to="/5/1">Выбрать квартиру</FooterLink>
        </div>
        <div>
          <FooterLink to="/news">новости</FooterLink>
        </div>
        <div>
          <FooterLink to="/contacts">контакты</FooterLink>
        </div>
        <div>
          <a href="https://zakupki.stroifed.ru/" target="_blank" rel="noreferrer" className="h5">
            Тендеры
          </a>
        </div>
        <div>
          <FooterLinkBlank href="https://наш.дом.рф/сервисы/каталог-новостроек/объект/43319">
            проектная декларация
          </FooterLinkBlank>
        </div>
        <div>
          <FooterLinkBlank href="https://наш.дом.рф/сервисы/каталог-новостроек/объект/43319">
            Ход строительства
          </FooterLinkBlank>
        </div>
        <div>
          <FooterLinkBlank href="https://ucams.ufanet.ru/api/internal/embed/1653372953YDQ270/?ttl=3600&autoplay=true&mute=true">
            Онлайн-камера
          </FooterLinkBlank>
        </div>
        <div>
          <FooterLinkBlank href={`${process.env.PUBLIC_URL}/docs/politics.pdf`}>
            политика конфиденциальности
          </FooterLinkBlank>
        </div>
      </div>
      <div className="mt-[27px]">
        <a href={`tel:${phone}`}>
          <div className="h1">{phone}</div>
        </a>
        <div
          className="h6 mt-[5px] whitespace-pre-line"
          dangerouslySetInnerHTML={{ __html: sanitizeHTML(address.complex) }}
        />
      </div>
      <div className="mt-[18px] flex gap-[10px]">
        {vk && (
          <div className="flex-none pt-[12px]">
            <a href={vk} target="_blank" rel="noreferrer">
              <Button variant="secondary">
                <VkIcon />
              </Button>
            </a>
          </div>
        )}
        {you && (
          <div className="flex-none">
            <a href={you} target="_blank" rel="noreferrer">
              <Button variant="gray" size="small">
                <YoutubeIcon />
              </Button>
            </a>
          </div>
        )}
      </div>
      <div className="mt-[40px] flex items-center">
        <div className="flex-auto">
          <Link to="/">
            <LogoIcon className="block h-[30px] w-[99px]" />
          </Link>
        </div>
        <div className="flex-none">
          <Itef />
        </div>
      </div>
      <div className="text3 mt-[10px] text-[8px]">
        Любая информация, представленная на данном сайте, носит исключительно информационный характер и ни при каких
        условиях не является публичной офертой, определяемой положениями статьи 437 ГК РФ.
      </div>
    </div>
  )
}

const FooterLink = ({ to, children }) => {
  return (
    <Link to={to} className="h5 hover:text-pink-100">
      {children}
    </Link>
  )
}

const FooterLinkBlank = ({ href, children }) => (
  <a href={href} target="_blank" rel="noreferrer" className="h5 hover:text-pink-100">
    {children}
  </a>
)
