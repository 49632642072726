import { Enum } from "shared/helpers"

const SALE = 0
const SOLD = 1
const BOOKED = 2

const BUILDING_SOON = "soon"
const BUILDING_SALE = "on_sale"
const BUILDING_SOLD = "sold"

const APARTMENT = "apartment"
const PARKING = "parking"
const COMMERCIAL = "commer"
const STORAGE = "storage"

export const PLACEMENT_STATUS = Enum({
  Sale: SALE,
  Sold: SOLD,
  Booked: BOOKED
})

export const PLACEMENT_STATUS_COLOR = Enum({
  [SALE]: "#E41170",
  [SOLD]: "#CBCBCB",
  [BOOKED]: "#F5F5F5"
})

export const PLACEMENT_STATUS_NAME = Enum({
  [SALE]: "В продаже",
  [SOLD]: "Продано",
  [BOOKED]: "Забронировано"
})

export const BUILDING_STATUS = Enum({
  Soon: BUILDING_SOON,
  Sale: BUILDING_SALE,
  Sold: BUILDING_SOLD
})

export const BUILDING_STATUS_COLOR = Enum({
  [BUILDING_SALE]: "#E41170",
  [BUILDING_SOLD]: "#CBCBCB",
  [BUILDING_SOON]: "#F5F5F5"
})

export const BUILDING_STATUS_NAME = Enum({
  [BUILDING_SOON]: "Ведётся строительство",
  [BUILDING_SALE]: "В продаже",
  [BUILDING_SOLD]: "Квартиры проданы"
})

export const PLACEMENT_TYPE = Enum({
  Apartment: APARTMENT,
  Parking: PARKING,
  Commercial: COMMERCIAL,
  Storage: STORAGE
})

export const PLACEMENT_TYPE_NAME = Enum({
  [APARTMENT]: "Квартира",
  [PARKING]: "Машиноместо",
  [COMMERCIAL]: "Коммерческое помещение",
  [STORAGE]: "Кладовая"
})
