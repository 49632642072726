import { BUILDING_STATUS, BUILDING_STATUS_COLOR, BUILDING_STATUS_NAME } from "../constans"
import { declOfNum } from "shared/helpers"

export function BuildingTooltip({ status, flatsAvailable }) {
  return (
    <div className="min-w-[152px] translate-y-[15%] rounded-[20px] bg-white p-[20px] shadow-3xl">
      <div className="font-comfortaa text-[15px] font-semibold uppercase leading-[1.4]">
        умный дом
        <br />
        <span className="text-pink-100">Гелиос</span>
      </div>
      <div className="mt-[20px]">
        <div className="flex items-center gap-[5px]">
          <div className="h-[10px] w-[10px] rounded-[50%]" style={{ backgroundColor: BUILDING_STATUS_COLOR[status] }} />
          <div className="font-middle text-[11px] uppercase">{BUILDING_STATUS_NAME[status]}</div>
        </div>
      </div>
      {status !== BUILDING_STATUS.Sold && flatsAvailable > 0 && (
        <div className="mt-[4px] flex items-baseline gap-[8px]">
          <div className="font-comfortaa text-[16px] font-semibold leading-[1.6]">{flatsAvailable}</div>
          <div className="text-[12px] font-medium">
            {declOfNum(flatsAvailable, ["Квартира", "Квартиры", "Квартир"])}
          </div>
        </div>
      )}
    </div>
  )
}
