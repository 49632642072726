import { Collapse } from "./collapse"

export function Security({ data }) {
  return (
    <div className="mt-[60px] md:mt-[120px] xl:mt-[150px]">
      <div className="text2 flex-none text-gray-500">Безопасность</div>
      <div className="md:grid-10 mt-[15px] max-md:flex max-md:flex-col md:mt-[40px]">
        <div className="col-start-1 col-end-4 max-md:order-3 max-md:mt-[20px]">
          <Collapse data={data} />
        </div>
        <div className="col-start-5 col-end-8 max-md:order-1">
          <img loading="lazy" src={require("../assets/s1.jpg")} alt="" className="block max-md:hidden" />
          <img loading="lazy" src={require("../assets/sm1.jpg")} alt="" className="block md:hidden" />
        </div>
        <div className="col-start-8 col-end-11 flex max-md:order-2 max-md:mt-[20px] md:justify-center md:pt-[120px] xl:pt-[180px]">
          <div className="h2">важнее всего</div>
        </div>
      </div>
    </div>
  )
}
