import { Button } from "shared/components/navigation"
import { ReactComponent as DownloadIcon } from "../assets/download.svg"
import { ReactComponent as GoldIcon } from "../assets/gold.svg"
import { ReactComponent as InnovationIcon } from "../assets/innovation.svg"
import { ReactComponent as PresentationIcon } from "../assets/presentation.svg"
import { RoundText } from "./roundText"
import { Video } from "shared/components/video"

export function About() {
  return (
    <>
      <div className="md:grid-10">
        <div className="col-start-1 col-end-6">
          <div className="text2 text-gray-500">О проекте</div>
          <div className="mt-[15px] md:hidden">
            <div className="h1">Первый в России многоквартирный дом с энергогенери&shy;рующим фасадом </div>
          </div>
          <div className="mt-[40px] flex justify-center md:mt-[65px] xl:mt-[70px] ">
            <a href="https://promo-stroifed.ru/preza.pdf" target="_blank" rel="noreferrer" className="block">
              <RoundText icon={<DownloadIcon className="block max-md:h-[17px] max-md:w-[21px]" />}>
                <PresentationIcon className="max-md:h-[90px] max-md:w-[90px]" />
              </RoundText>
            </a>
          </div>
        </div>
        <div className="col-start-6 col-end-11 max-md:hidden">
          <div className="h1">Первый в России многоквартирный дом с&nbsp;энергогенерирующим фасадом </div>
        </div>
      </div>
      <div className="md:grid-10 mt-[40px] md:mt-[50px] xl:mt-[70px]">
        <div className="col-start-1 col-end-5">
          <div className="max-md:hidden">
            <Video src={require("../assets/v1.jpg")} videoId="CS6Sc2_3H5k" />
          </div>
          <div className="md:hidden">
            <Video src={require("../assets/vm1.jpg")} videoId="CS6Sc2_3H5k" />
          </div>
        </div>
        <div className="col-start-6 col-end-11 max-md:mt-[40px]">
          <div className="h-[100%] flex-col md:flex">
            <div className="flex-auto">
              <div className="flex gap-[20px] max-md:flex-col max-md:items-center md:gap-[30px]">
                <div>
                  <InnovationIcon className="h-[87px] w-[234px] max-w-[100%] xl:h-[117px] xl:w-[300px]" />
                </div>
                <div>
                  <GoldIcon className="h-[87px] w-[234px] max-w-[100%] xl:h-[117px] xl:w-[300px]" />
                </div>
              </div>
            </div>
            <div className="mt-[30px] flex flex-none justify-center md:mt-[50px] md:pb-[16px] xl:pb-[75px]">
              <a href="https://promo-stroifed.ru/preza.pdf" target="_blank" rel="noreferrer">
                <Button variant="secondary">скачать презентацию</Button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
