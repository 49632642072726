import "swiper/css"
import "swiper/css/autoplay"

import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Autoplay, Navigation } from "swiper"
import { useCallback, useEffect, useRef, useState } from "react"

import { ReactComponent as ArrowNextIcon } from "../assets/arrow-next.svg"
import { ReactComponent as ArrowPrevIcon } from "../assets/arrow-prev.svg"
import { ReactComponent as FlatIcon } from "../assets/flat.svg"
import { ReactComponent as FreeIcon } from "../assets/free-swiper.svg"
import { RoundText } from "./roundText"
import { ReactComponent as StarIcon } from "../assets/star.svg"
import { useDomEvent } from "shared/hooks"

SwiperCore.use([Autoplay, Navigation])

export function AdvantagesSwiper({ data, onChangeSlide, onSwiper }) {
  const $container = useRef()

  const [active, setActive] = useState()
  const [swiperHeight, setSwiperHeight] = useState()

  const onSlideChange = useCallback(
    swiper => {
      const index = swiper.realIndex
      onChangeSlide(index)
      setActive(index)
    },
    [setActive, onChangeSlide]
  )

  const updateSwiperHeight = useCallback(() => {
    if ($container.current) {
      const height = $container.current.offsetHeight
      setSwiperHeight(height)
    }
  }, [setSwiperHeight])

  useDomEvent("resize", updateSwiperHeight)
  useEffect(updateSwiperHeight, [updateSwiperHeight])

  return (
    <div className="relative h-[100%] w-[100%] overflow-hidden rounded-[20px]" ref={$container}>
      {swiperHeight && (
        <Swiper
          onSwiper={onSwiper}
          onSlideChange={onSlideChange}
          loop={true}
          speed={1000}
          spaceBetween={40}
          slidesPerView={"auto"}
          centeredSlides={true}
          style={{ height: swiperHeight }}
          navigation={{
            prevEl: ".swiper-prev",
            nextEl: ".swiper-next"
          }}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false
          }}
        >
          {data.map(image => (
            <SwiperSlide key={image.id} style={{ height: "100%", width: "auto" }}>
              <img loading="lazy" alt="" className="block h-[100%] rounded-[20px]" src={image.src} />
              {image.each && (
                <div className="absolute top-[40px] left-[40px]">
                  <RoundText icon={<StarIcon className="block h-[11px] w-[11px] xl:h-[14px] xl:w-[14px]" />}>
                    <FlatIcon className="h-[75px] w-[75px] xl:h-[93px] xl:w-[93px]" />
                  </RoundText>
                </div>
              )}
              {image.free && (
                <div className="absolute top-[40px] left-[40px]">
                  <RoundText icon={<StarIcon className="block h-[11px] w-[11px] xl:h-[14px] xl:w-[14px]" />}>
                    <FreeIcon className="h-[75px] w-[75px] xl:h-[93px] xl:w-[93px]" />
                  </RoundText>
                </div>
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      )}
      <div className="absolute top-0 left-0 right-0 bottom-0 z-[9] flex justify-center">
        <div className="flex aspect-[889/675] justify-end p-[40px]">
          <div className="flex flex-col items-center">
            <div className="mt-[6px] flex gap-[14px]">
              <div className="swiper-prev cursor-pointer">
                <ArrowPrevIcon />
              </div>
              <div className="swiper-next cursor-pointer">
                <ArrowNextIcon />
              </div>
            </div>
            <div className="mt-[8px] text-right text-[12px] leading-[1.55] text-gray-500 md:text-[14px] xl:text-[18px]">
              {active + 1}/{data.length}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
