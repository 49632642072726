import { format, parseISO } from "date-fns"

import { IconButton } from "shared/components/navigation"
import { Link } from "react-router-dom"
import { prefetchNewsById } from "../api"

export function NewsCard({ id, name, description, datetime, picture }) {
  return (
    <Link to={`/news/${id}`} onMouseEnter={() => prefetchNewsById(id)}>
      <div className="group flex flex-col gap-[15px] md:flex-row md:gap-[20px]">
        <div className="flex-1">
          <div className="md:flex md:h-[100%] md:flex-col">
            <div className="flex-auto">
              <div className="md:pr-[20%]">
                {datetime && <div className="text2 text-gray-500">{format(parseISO(datetime), "dd/MM/yyyy")} г.</div>}
                {name && <h2 className="h2 mt-[10px] md:mt-[20px]">{name}</h2>}
                {description && <div className="text1 mt-[20px] text-gray-500 md:mt-[30px]">{description}</div>}
              </div>
            </div>
            <div className="flex-none pt-[15px]">
              <IconButton icon="arrowUp" />
            </div>
          </div>
        </div>
        {picture && (
          <div className="flex-1">
            <div className="aspect-[288/162] overflow-hidden rounded-[20px] md:aspect-[573/322] xl:aspect-[850/470]">
              <img alt="" className="block h-[100%] w-[100%]" src={picture} />
            </div>
          </div>
        )}
      </div>
    </Link>
  )
}
