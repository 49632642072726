import { ReactComponent as ApartmentsIcon } from "../assets/apartments.svg"
import { ApartmentsSwiper } from "../components/apartments-swiper"
import { Collapse } from "./collapse"
import { Form } from "./form"
import { RoundText } from "./roundText"
import { ReactComponent as StarIcon } from "../assets/star.svg"
import { SwiperSm } from "./swiper-sm"

export function Apartments({ data, swiper }) {
  return (
    <div className="mt-[60px] md:mt-[120px] xl:mt-[140px]">
      <div className="text2 flex-none text-gray-500">Квартиры</div>
      <div className="md:grid-10 mt-[15px] md:mt-[20px]">
        <div className="col-start-1 col-end-4">
          <div className="h2">
            Место для&nbsp;жизни,
            <br /> Отражает вашу индивидуальность и&nbsp;стиль
          </div>
          <div className="mt-[30px] flex justify-center xl:mt-[90px] xl:mt-[80px]">
            <RoundText icon={<StarIcon className="block h-[16px] w-[16px] xl:h-[24px] xl:w-[24px]" />}>
              <ApartmentsIcon className="block max-md:h-[90px] max-md:w-[90px]" />
            </RoundText>
          </div>
        </div>
        <div className="col-start-4 col-end-7 max-md:hidden">
          <Collapse data={data} />
        </div>
        <div className="col-start-8 col-end-11 max-md:hidden">
          <ApartmentsSwiper data={swiper} />
        </div>
        <div className="mt-[30px] md:hidden">
          <SwiperSm data={swiper} />
        </div>
        <div className="mt-[30px] md:hidden">
          <Collapse data={data} />
        </div>
      </div>
      <Form>
        <div className="h2">
          Остались
          <br className="max-md:hidden" /> вопросы?
        </div>
        <br />
        <div className="text1">Перезвоним и ответим на них.</div>
      </Form>
    </div>
  )
}
