import { useSeo, useThrowError } from "shared/hooks"
import { Cover } from "shared/components/layout"
import { MetaTags } from "shared/components/seo"
import { NewsList } from "../components/news-list"
import { motion } from "framer-motion"
import { useNews } from "../api"

export function NewsListPage() {
  const { error, data: { data: news, meta } = {} } = useNews()
  useThrowError(error)
  const { title, description } = useSeo(meta?.seo)

  return (
    <div className="wrap">
      <motion.div
        initial={{ y: 40, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ default: { ease: "easeOut", duration: 1, delay: 1.2 } }}
      >
        <MetaTags title={title} description={description} />
        <h1 className="h1 max-md:text-center">Новости</h1>
        <div className="mt-[30px] md:mt-[40px] xl:mt-[60px]">{news && <NewsList news={news} />}</div>
      </motion.div>
      <Cover />
    </div>
  )
}
