export { default as cx } from "classnames"

export const createPager = (el, arr) => {
  const index = arr.indexOf(el)

  const count = arr.length
  const page = index >= 0 ? index + 1 : 1

  const prev = arr[index - 1]
  const next = index !== -1 ? arr[index + 1] : void 0

  return { prev, next, page, count, curent: el }
}

export const sanitizeHTML = html => html

export const jsonSWR = (res, data) => {
  const meta = res.data?.meta
  return { ...res, data: { data, meta } }
}

export function addMockProps(WrappedComponent, props) {
  return () => <WrappedComponent {...props} />
}

export function printJSON(json) {
  const prettyJSON = JSON.stringify(json, null, 2)
  return { __html: `<div style="overflow: scroll"><pre>${prettyJSON}</pre></div>` }
}

export function declOfNum(number, titles) {
  const cases = [2, 0, 1, 1, 1, 2]
  return titles[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]]
}

export const Enum = obj => {
  const keysByValue = new Map()
  const EnumLookup = value => keysByValue.get(value)

  for (const key of Object.keys(obj)) {
    EnumLookup[key] = obj[key]
    keysByValue.set(EnumLookup[key], key)
  }

  return Object.freeze(EnumLookup)
}
