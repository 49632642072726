import { PLACEMENT_STATUS_COLOR, PLACEMENT_STATUS_NAME } from "../constans"
import { ReactComponent as CircleIcon } from "../assets/circle.svg"
import { cx } from "shared/helpers"

export function Status({ statusId, variant = "default" }) {
  const textCx = {
    default: "text-[11px] font-medium xl:font-semibold",
    print: "text-[12px] font-semibold",
    popup: "text-[11px] font-semibold",
    head: "text-[15px]"
  }[variant]

  const circleCx = {
    default: "h-[14px] w-[14px]",
    print: "h-[14px] w-[14px]",
    popup: "h-[10px] w-[10px]",
    head: "h-[14px] w-[14px]"
  }[variant]

  return (
    <div key={statusId} className="flex items-center gap-[10px]">
      <div className="flex-none">
        <CircleIcon className={cx("h2", circleCx)} style={{ color: PLACEMENT_STATUS_COLOR[statusId] }} />
      </div>
      <div className={cx("h2", textCx)}>{PLACEMENT_STATUS_NAME[statusId]}</div>
    </div>
  )
}
