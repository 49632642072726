export const live = [
  {
    id: 0,
    name: "Митинг рум",
    text: (
      <>
        Комната для проведения мероприятий расположена на первом этаже комплекса. Здесь жители смогут организовать
        детский день рождения или мастер-класс, а также провести деловую встречу или просто поиграть в приставку.
      </>
    )
  },
  {
    id: 1,
    name: "свой тренажерный зал",
    text: (
      <>
        Для удобства жителей на первом этаже дома для жителей будет оборудован свой тренажерный зал в формате
        фитнес-студии.
      </>
    )
  },
  {
    id: 2,
    name: "Благоустроенный двор",
    text: (
      <>
        Все жители комплекса найдут здесь место для отдыха. Для детей предусмотрены современные детские площадки и
        игровые зоны. Для взрослых — зоны для спокойного отдыха и места для занятий спортом.
      </>
    )
  },
  {
    id: 3,
    name: "просторный холл",
    text: (
      <>
        На первом этаже для жителей предусмотрен просторный общедоступный холл площадью более 300 кв.м. Это еще одна
        точка притяжения жителей, место для встреч и ожиданий.
      </>
    )
  }
]
