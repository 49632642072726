import "swiper/css"
import "swiper/css/autoplay"

import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Autoplay } from "swiper"

SwiperCore.use([Autoplay])

export function SwiperSm({ data, onSwiper = () => {} }) {
  return (
    <div className="w-[100%] overflow-hidden">
      <Swiper
        onSwiper={onSwiper}
        loop={false}
        speed={1000}
        spaceBetween={20}
        slidesPerView={"auto"}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false
        }}
      >
        {data.map(image => (
          <SwiperSlide key={image.id} style={{ height: "auto", width: "67vw", maxWidth: "100%" }}>
            <div className="aspect-[214/162] h-[auto] w-[100%]">
              <img
                loading="lazy"
                alt=""
                className="block h-[100%] w-[100%] rounded-[20px] object-cover"
                src={image.srcSm || image.src}
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}
