import { Button, IconButton } from "shared/components/navigation"
import { Link, useParams } from "react-router-dom"
import { debounce, get } from "lodash"
import { prefetchPlacement, usePlacementById } from "../api"
import { useCallback, useMemo } from "react"
import { useSeo, useThrowError } from "shared/hooks"

import { Compass } from "../components/compass"
import { FloorPickerWidget } from "../components/floor-picker-widget"
import { FloorSchema } from "../components/floor-schema"
import { Gallery } from "shared/components/gallery"
import { GenplanSchema } from "../components/genplan-schema"
import { MetaTags } from "shared/components/seo"
import { Modal } from "shared/components/modal"
import { PLACEMENT_STATUS } from "../constans"
import { PlacementLevelsSwitcher } from "../components/placement-levels-switcher"
import { PlacementPlan } from "../components/placement-plan"
import { PlacementTooltip } from "../components/placement-tooltip"
import { ReactComponent as PrintIcon } from "../assets/print.svg"
import { ReservationForm } from "../components/reservation-form"
import { useModal } from "shared/store"

export function PlacementPage() {
  const params = useParams()
  const placementId = params.id

  const { openModal, closeModal } = useModal()
  const { error, data: { data, meta } = {} } = usePlacementById(placementId)
  useThrowError(error)
  const { title, description } = useSeo(meta?.seo)

  const getVal = useCallback(
    (key, defaultValue) => {
      const value = get(data?.currentFloor, key)
      return value || defaultValue
    },
    [data]
  )

  const { section, floor, ...rest } = meta ?? {}
  const building = rest?.building?.buildingId
  const placement = getVal("placement", {})
  const compasDegree = getVal("compasDegree", 0)
  const viewbox = getVal("floorPlanViewbox", "0 0 1781 838")
  const floorPlan = getVal("floorPlan")
  const placements = getVal("placements", [])
  const files = placement?.planType?.files

  const GalleryModal = () => (
    <Modal onClose={closeModal}>
      <Gallery files={files} />
    </Modal>
  )

  const openGallery = e => {
    e.stopPropagation()
    e.preventDefault()
    openModal(<GalleryModal />)
  }

  const openForm = () => {
    openModal(
      <Modal onClose={closeModal}>
        <ReservationForm placementUniCode={placement.uniCode} placementNumber={placement.placementNumber} />
      </Modal>
    )
  }

  const debouncedPrefetch = useMemo(() => {
    return debounce(prefetchPlacement, 100)
  }, [])

  const prefetch = useCallback(
    (ev, placement) => {
      debouncedPrefetch(placement.uniCode)
    },
    [debouncedPrefetch]
  )

  return (
    <div className="wrap max-md: top-0 bottom-0 right-0 left-0 pt-[25px] md:absolute md:py-[60px] xl:py-[100px]">
      <MetaTags title={title} description={description} />
      <div className="md:grid-10 h-[100%]">
        <div className="relative relative col-start-1 col-end-3 overflow-hidden md:h-[100%]">
          <PlacementTooltip placement={placement} buttons={<Buttons apartmentUi={placement.uniCode} variant="head" />}>
            {placement.status !== PLACEMENT_STATUS.Sold && (
              <>
                <div className="max-md:hidden">
                  <Button onClick={openForm}>заброни&shy;ровать</Button>
                </div>
                <div className="flex justify-center md:hidden">
                  <Button onClick={openForm} style={{ width: 98, height: 98 }}>
                    заброни&shy;ровать
                  </Button>
                </div>
              </>
            )}
          </PlacementTooltip>
        </div>
        <div className="col-start-3 col-end-4 self-end justify-self-end max-md:hidden">
          <Compass deg={compasDegree} />
        </div>
        <div className="col-start-4 col-end-8 max-md:mt-[30px]">
          <div className="md:flex md:h-[100%] md:flex-col">
            <div className="relative flex-auto">
              <PlacementPlan
                planType={placement.planType}
                onGalleryButtonClick={openGallery}
                showGalleryButton={files && files.length > 0}
              />
            </div>
            {placement.levels && (
              <div className="flex-none pt-[30px]">
                <PlacementLevelsSwitcher levels={placement.levels} />
              </div>
            )}
          </div>
        </div>
        <div className="col-start-9 col-end-11 flex flex-col gap-[30px] max-md:mt-[24px]">
          <div className="flex-auto max-md:flex max-md:items-center">
            <div className="flex-auto max-md:flex max-md:flex-col-reverse">
              <div className="max-md:hidden">
                <FloorSchema
                  viewbox={viewbox}
                  schema={floorPlan}
                  placements={placements}
                  scalable={true}
                  onMouseEnter={prefetch}
                  placementUniCode={placement.uniCode}
                />
              </div>
              <div className="md:hidden">
                <FloorSchema
                  viewbox={viewbox}
                  schema={floorPlan}
                  placements={placements}
                  placementUniCode={placement.uniCode}
                  variant="sm"
                />
              </div>
              <div className="flex items-center justify-between max-md:mb-[26px] md:mt-[20px]">
                <div>
                  <Link to={`/floor/${building}/${section}/${floor}`} className="group">
                    <div className="max-md:hidden">
                      <IconButton icon="arrowUp" />
                    </div>
                    <div className="md:hidden">
                      <IconButton icon="arrowDown" />
                    </div>
                  </Link>
                </div>
                <div className="flex items-baseline gap-[10px] max-md:hidden">
                  <div className="h2">{floor}</div>
                  <div className="text2">Этаж</div>
                </div>
                <div className="md:hidden">
                  <FloorPickerWidget building={building} section={section} variant="sm" floor={floor} />
                </div>
                <div className="md:hidden">
                  <Compass deg={compasDegree} />
                </div>
              </div>
            </div>
          </div>
          <div className="flex-none max-md:mt-[30px] max-md:hidden">
            <div className="xl:ml-[-20px]">
              <GenplanSchema selectedBuildings={{ [+building]: [+section] }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const Buttons = ({ apartmentUi }) => (
  <div>
    <Link to={`/placement/${apartmentUi}/print`} className="group text-gray-500">
      <Button size="small" variant="gray">
        <PrintIcon />
      </Button>
    </Link>
  </div>
)
