export const apartments = [
  {
    id: 0,
    name: "теплый пол",
    text: <>В каждом санузле квартиры будет установлен электрический теплый пол.</>
  },
  {
    id: 1,
    name: "пРЕДЧИСТОВАЯ ОТДЕЛКА wHITE bOX",
    text: (
      <>
        Все квартиры комплекса будут выполнены в предчистовой отделке Whitebox, включающая внутренние перегородки стен,
        стяжку пола, штукатурку, разводку электрики и отопления, надежную входную дверь и натяжной потолок.
      </>
    )
  },
  {
    id: 2,
    name: "система умный дом",
    text: (
      <>
        Помимо прочих инновационных преимуществ система «Умный дом» будет включать систему бесключевого доступа,
        видеодомофон и видеонаблюдение.
      </>
    )
  },
  {
    id: 3,
    name: "кондиционер",
    text: (
      <>
        В каждой квартире уже будет предустановлен кондиционер. Вам не нужно будет беспокоиться о монтаже кондиционера в
        квартире, он уже будет включен в комплектацию вашей квартиры.
      </>
    )
  }
]
