import { NewsCard } from "./news-card"

export function NewsList({ news }) {
  return (
    <div className="grid gap-[50px] md:gap-[100px] xl:gap-[150px]">
      {news.map(news => (
        <NewsCard
          key={news.id}
          id={news.id}
          name={news.name}
          description={news.annotation}
          datetime={news.publishedAt}
          picture={news.pictureUrl}
        />
      ))}
    </div>
  )
}
