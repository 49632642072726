import { Button } from "shared/components/navigation"
import { IconButton } from "shared/components/navigation"
import { Link } from "react-router-dom"

export function NotFound() {
  return (
    <div className="fixed left-0 right-0 top-0 bottom-0 flex min-h-screen items-center max-md:w-[100%] max-md:min-w-[288px] max-md:px-[16px] max-md:py-[20px] md:justify-center">
      <div className="max-md:w-[100%] md:flex md:h-[383px] md:gap-[80px] xl:h-[575px] xl:gap-[107px]">
        <div className="group flex h-[100%] flex-1 justify-center md:items-end md:justify-end md:pb-[60px] xl:pb-[70px]">
          <Link to="/">
            <IconButton icon="arrowUp" />
          </Link>
        </div>
        <div className="flex-none max-md:mt-[25px]">
          <div className="flex w-[100%] items-center justify-center rounded-[257px] bg-gray-600 max-md:aspect-[288/328] md:h-[100%] md:w-[335px] xl:w-[502px]">
            <div className="text-center md:text-right">
              <h1 className="text-[50px] font-medium leading-[1.2] md:text-[100px] xl:text-[200px]">404</h1>
              <div className="text2 md:mt-[-16px] xl:mt-[-34px]">Страница не найдена</div>
            </div>
          </div>
        </div>
        <div className="h-[100%] flex-1 max-md:mt-[22px] max-md:flex max-md:justify-center md:pt-[10px] xl:pt-[27px]">
          <Link to="/">
            <Button>на главную</Button>
          </Link>
        </div>
      </div>
    </div>
  )
}
