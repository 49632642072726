import { Collapse } from "./collapse"
import { SwiperSm } from "./swiper-sm"

export function Live({ data, swiper }) {
  return (
    <div className="mt-[60px] md:mt-[120px] xl:mt-[150px]">
      <div className="text2 flex-none text-gray-500">Пространство для жизни</div>
      <div className="md:grid-10 mt-[15px] md:mt-[20px] xl:mt-[40px]">
        <div className="col-start-1 col-end-4 max-md:hidden">
          <Collapse data={data} />
        </div>
        <div className="col-start-5 col-end-11">
          <div className="flex md:justify-center">
            <div className="h2 w-[fit-content]">
              Наслаждайтесь любовью
              <br /> к себе и своим близким
            </div>
          </div>
          <div className="mt-[20px] flex gap-[20px] max-md:hidden md:mt-[130px] xl:mt-[124px]">
            <div>
              <img loading="lazy" src={require("../assets/l1.jpg")} alt="" className="block" />
            </div>
            <div>
              <img loading="lazy" src={require("../assets/l2.jpg")} alt="" className="block" />
            </div>
          </div>
          <div className="mt-[20px] overflow-y-hidden md:hidden">
            <SwiperSm data={swiper} />
          </div>
        </div>
        <div className="mt-[30px] md:hidden">
          <Collapse data={data} />
        </div>
      </div>
    </div>
  )
}
