import { AnimatePresence, motion } from "framer-motion"

import { CookieInformer } from "./cookie-informer"
import { ScrollRestoration } from "react-router-dom"
import { useApplyModalStyle } from "shared/hooks"
import { useModal } from "shared/store"

export function Skeleton({ children, headerEl, footerEl }) {
  const { modalIsOpen, modalComponent } = useModal()
  useApplyModalStyle(modalIsOpen)

  return (
    <>
      <div className="relative flex min-h-screen flex-col max-md:z-[1]">
        <div className="relative min-h-screen md:z-[1] md:flex md:flex-col">
          {headerEl && <>{headerEl}</>}
          <div className="relative flex-auto">{children}</div>
        </div>
        {footerEl && <div className="flex-none">{footerEl}</div>}
        <AnimatePresence>
          {modalIsOpen && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ default: { ease: "easeOut" } }}
              className="fixed top-0 bottom-0 right-0 left-0 z-[9] block bg-white max-md:overflow-y-auto"
            >
              {modalComponent}
            </motion.div>
          )}
        </AnimatePresence>
        <ScrollRestoration />
      </div>
      <CookieInformer />
    </>
  )
}
