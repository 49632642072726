export function PrintFooter() {
  return (
    <div className="border-black pb-[5px] pt-[35px]">
      <div className="font-comfortaa text-[20px] uppercase leading-[1.2]">Прошу вас забронировать</div>
      <div className="mt-[30px] grid grid-cols-6 gap-[20px]">
        <div className="col-start-1 col-end-5">
          <Row>ФИО</Row>
        </div>
        <div className="col-start-5 col-end-7">
          <Row>Подпись</Row>
        </div>
      </div>
      <div className="mt-[20px] grid grid-cols-6 gap-[20px]">
        <div className="col-start-1 col-end-3">
          <Row>Дата</Row>
        </div>
        <div className="col-start-3 col-end-5">
          <Row>Номер телефона</Row>
        </div>
        <div className="col-start-5 col-end-7">
          <Row>Примечание</Row>
        </div>
      </div>
      <div className="mt-[35px] text-right text-[8px] font-medium text-gray-800">
        Сформировано: {new Date().toLocaleString()}
      </div>
    </div>
  )
}
const Row = ({ children }) => (
  <div className="border-t border-gray-500 py-[4px] text-[15px] font-medium leading-[1.4]">{children}</div>
)
