import { NumericFormat } from "react-number-format"
import { PLACEMENT_STATUS } from "../constans"
import { Status } from "./status"
import { cx } from "shared/helpers"
import { declOfNum } from "shared/helpers"

export function PlacementTooltip({ placement = {}, variant = "default", buttons, children }) {
  const { placementNumber, status, rooms, square, apartmentCost, levels } = placement

  const placementNumberMarginCx = {
    default: "mt-[15px] mb-[20px] xl:mb-[30px] xl:mt-[10px] md:mt-[20px]",
    popup: "mt-[10px] mb-[10px]"
  }[variant]

  const placementNumberCx = {
    default: "text-[50px] xl:text-[70px]",
    popup: "font-comfortaa text-[20px]"
  }[variant]

  const infoSx = {
    default: "gap-[12px] md:gap-[15px] xl:gap-[20px]",
    popup: "gap-[10px]"
  }[variant]

  const sold = status === PLACEMENT_STATUS.Sold

  return (
    <div>
      <div>
        <div className="flex">
          <div className="flex-auto">
            <Status statusId={status} variant={variant} />
            {placementNumber && (
              <div className={cx("flex items-baseline gap-[10px]", placementNumberMarginCx)}>
                <div className={cx("font-medium leading-[1]", placementNumberCx)}>{placementNumber}</div>
                <Label variant={variant}>№ квартиры</Label>
              </div>
            )}
          </div>
          {buttons && <div className="flex-none">{buttons}</div>}
        </div>
        <div className="max-md:flex">
          <div className={cx("flex flex-auto flex-col", { "text-gray-300": sold }, infoSx)}>
            {rooms > 0 && (
              <div className="flex items-baseline gap-[10px]">
                <Value variant={variant}>{rooms}</Value>
                <Label variant={variant} sold={sold}>
                  {declOfNum(rooms, ["Комната", "Комнаты", "Комнат"])}
                  {levels && (
                    <>
                      {" "}
                      -<span className="ml-[10px] inline-block text-pink-100">двухуровневая</span>
                    </>
                  )}
                </Label>
              </div>
            )}
            {square && (
              <div className="flex items-baseline gap-[10px]">
                <Value variant={variant}>{square}</Value>
                <Label variant={variant} sold={sold}>
                  м<sup>2</sup>
                </Label>
              </div>
            )}
            {apartmentCost && (
              <div className="flex items-baseline gap-[10px]">
                <Value variant={variant}>
                  <NumericFormat value={apartmentCost} displayType={"text"} thousandSeparator={" "} />
                </Value>
                <Label variant={variant} sold={sold}>
                  ₽
                </Label>
              </div>
            )}
          </div>
          {children && <div className="flex-none md:hidden">{children}</div>}
        </div>
      </div>
      {children && <div className="mt-[30px] max-md:hidden md:mt-[40px] xl:mt-[70px]">{children}</div>}
    </div>
  )
}

const Value = ({ children, variant }) => {
  const variantSx = {
    default: "max-md:text-[20px]",
    popup: "text-[16px]"
  }[variant]
  return <div className={cx("h2 font-semibold", variantSx)}>{children}</div>
}

const Label = ({ children, variant, sold }) => {
  const variantSx = {
    default: "xl:text-[14px]",
    popup: "xl:text-[12px]"
  }[variant]
  return (
    <div className={cx("text-[12px] font-medium text-gray-500", { "text-gray-300": sold }, variantSx)}>{children}</div>
  )
}
