export const advantages = [
  {
    id: 0,
    name: "Инновационный фасад",
    src: require("../assets/as1.jpg")
  },
  {
    id: 1,
    name: "Витражное остекление",
    src: require("../assets/as2.jpg")
  },
  {
    id: 2,
    name: "отделка White box",
    src: require("../assets/as3.jpg"),
    each: true
  },
  {
    id: 3,
    name: "кондиционер и теплый пол",
    src: require("../assets/as4.jpg"),
    each: true
  },
  {
    id: 4,
    name: "свой тренажерный зал",
    src: require("../assets/as5.jpg"),
    free: true
  },
  {
    id: 5,
    name: "митинг рум",
    src: require("../assets/as6.jpg"),
    free: true
  },
  {
    id: 6,
    name: "Face ID",
    src: require("../assets/as7.jpg")
  },
  {
    id: 7,
    name: "Колясочные",
    src: require("../assets/as8.jpg")
  },
  {
    id: 8,
    name: "Лифты",
    src: require("../assets/as9.jpg")
  }
]
