import { ErrorLabel, SubmitSuccess } from "shared/components/forms"
import { Button } from "shared/components/navigation"
import { PatternFormat } from "react-number-format"
import { useForm } from "shared/hooks"
import { useState } from "react"

export function ReservationForm({ placementUniCode, placementNumber }) {
  const [formatState, setFormatState] = useState(false)
  const { state, setValue, submit, error, response } = useForm({
    requestType: "rezerv",
    flatuid: placementUniCode,
    phone: "",
    name: ""
  })

  return (
    <div className="md:grid-10 h-[100%] items-center">
      <div className="col-start-1 col-end-5">
        <div className="md:mr-[-20px]">
          {response ? (
            <SubmitSuccess />
          ) : (
            <>
              <h1 className="h1 text-center">заявка на квартиру</h1>
              <div className="mt-[5px] md:hidden">
                <ApartmentInfo>{placementNumber}</ApartmentInfo>
              </div>
              <div className="mt-[20px] md:mt-[46px] xl:mt-[97px]">
                <div>
                  <input
                    placeholder="имя"
                    value={state.name}
                    className="input md:h2 w-[100%] md:h-[102px]"
                    onChange={ev => setValue("name", ev.target.value)}
                  />
                  {error.name && <ErrorLabel message={error.name} />}
                </div>
                <div className="mt-[20px] md:mt-[30px]">
                  <PatternFormat
                    placeholder="телефон"
                    value={state.phone}
                    mask="_"
                    allowEmptyFormatting={formatState}
                    format={`+7 (###) ###-##-##`}
                    className="input md:h2 w-[100%] md:h-[102px]"
                    onChange={ev => setValue("phone", ev.target.value === "+7 (___) ___-__-__" ? "" : ev.target.value)}
                    onMouseEnter={() => setFormatState(true)}
                    onMouseLeave={() => {
                      setFormatState(false)
                    }}
                  />
                  {error.phone && <ErrorLabel message={error.phone} />}
                </div>
                <div className="mt-[10px] text-[10px] font-medium leading-[1.2] text-gray-500 max-md:text-center">
                  Нажимая кнопку “Заказать звонок”, вы даете{" "}
                  <a
                    href={`${process.env.PUBLIC_URL}/docs/soglasie.pdf`}
                    target="_blank"
                    rel="noreferrer"
                    className="hover:underline"
                  >
                    согласие
                  </a>{" "}
                  на обработку персональных данных
                </div>
                <div className="mt-[35px] flex justify-center md:mt-[62px] xl:mt-[80px]">
                  <Button onClick={submit}>Оставить заявку</Button>
                </div>
              </div>
            </>
          )}
          {response && (
            <div className="mt-[40px] md:hidden">
              <ApartmentInfo>{placementNumber}</ApartmentInfo>
            </div>
          )}
        </div>
      </div>
      <div className="col-start-7 col-end-10 max-md:hidden">
        <ApartmentInfo>{placementNumber}</ApartmentInfo>
      </div>
    </div>
  )
}

const ApartmentInfo = ({ children }) => (
  <div className="flex aspect-[502/575] items-center justify-center rounded-[500px] bg-gray-600 text-center md:text-right">
    <div>
      <div className="text-[50px] font-medium leading-[1.2] md:text-[100px] xl:text-[200px]">{children}</div>
      <div className="text2  mt-[6px] md:mt-[3px] xl:mt-0">№ квартиры</div>
    </div>
  </div>
)
