import { Collapse } from "./collapse"
import { Video } from "shared/components/video"

export function Pano({ data }) {
  return (
    <div className="mt-[60px] md:mt-[120px] xl:mt-[200px]">
      <div className="md:grid-10">
        <div className="col-start-1 col-end-7 max-md:hidden">
          <Video src={require("../assets/v3.jpg")} videoId="neV6BjtbC10" />
        </div>
        <div className="col-start-8 col-end-11">
          <div className="text2 flex-none text-gray-500">Панорамные виды</div>
          <div className="h7 mt-[15px] font-semibold leading-[1.8] md:mt-[20px]">
            Из окон квартир открывается превосходный вид на панораму города, Парк Лесоводов Башкирии и Ботанический сад,
            а также на реки Белая и Уфа.
          </div>
          <div className="mt-[20px] md:hidden">
            <Video src={require("../assets/v3.jpg")} videoId="neV6BjtbC10" />
          </div>
          <div className="mt-[20px] md:mt-[50px] xl:mt-[96px]">
            <Collapse data={data} />
          </div>
        </div>
      </div>
    </div>
  )
}
