export const location = [
  {
    id: 0,
    name: "В окружении природы",
    text: (
      <>
        Живите с видом на лес. Рядом Зеленая роща, Парк Лесоводов, Ботанический сад, озеро с уточками на улице Сагита
        Агиша.
      </>
    )
  },
  {
    id: 1,
    name: "Вся необходимая инфраструктура",
    text: (
      <>
        Рядом находится все для комфортной жизни современного человека: детские сады, школы, торговые центры,
        фитнес-клубы, парки, поликлиники.
      </>
    )
  },
  {
    id: 2,
    name: "Удобная транспортная развязка",
    text: (
      <>
        Комплекс имеет близкий выезд на проспект Салавата Юлаева, что позволит быстро и удобно добраться в любую точку
        города.
      </>
    )
  }
]
