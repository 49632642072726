import { BlankLayout, Footer, Header, Layout } from "shared/components/layout"
import { FloorPage, PlacementPage, PrintPlacementPage } from "features/placements"
import { NewsDetailsPage, NewsListPage } from "features/news"

import { CompanyPage } from "features/company"
import { ContactsPage } from "features/contacts"
import { LandingPage } from "features/landing"
import { NotFound } from "shared/components/not-found"
import { createBrowserRouter } from "react-router-dom"

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <NotFound />,
    children: [
      {
        path: "news",
        element: <NewsListPage />
      },
      {
        path: "news/:id",
        element: <NewsDetailsPage />
      },
      {
        path: "company",
        element: <CompanyPage />
      },
      {
        path: "contacts",
        element: <ContactsPage />
      }
    ]
  },
  {
    element: <Layout footerEl={<Footer md={false} />} />,
    errorElement: <NotFound />,
    children: [
      {
        path: "floor/:building/:section",
        element: <FloorPage />
      },
      {
        path: "floor/:building/:section/:floor",
        element: <FloorPage />
      },
      {
        path: "placement/:id",
        element: <PlacementPage />
      }
    ]
  },
  {
    element: <BlankLayout />,
    errorElement: <NotFound />,
    children: [
      {
        path: "placement/:id/print",
        element: <PrintPlacementPage />
      }
    ]
  },
  {
    element: <Layout headerEl={<Header variant="overlay" />} />,
    errorElement: <NotFound />,
    children: [
      {
        index: true,
        element: <LandingPage />
      }
    ]
  }
])
