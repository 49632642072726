import { useSeo, useThrowError } from "shared/hooks"
import { Button } from "shared/components/navigation"
import { CompanyFace } from "../components/company-face"
import { CompanyProject } from "../components/company-project"
import { CompanySwiper } from "../components/company-swiper"
import { Cover } from "shared/components/layout"
import { MetaTags } from "shared/components/seo"
import { ReactComponent as StroyIcon } from "../assets/stroy.svg"
import { Video } from "shared/components/video"
import { addMockProps } from "shared/helpers"
import { mock } from "../mock"
import { motion } from "framer-motion"
import { useEmployees } from "../api"
import { useRef } from "react"

export const CompanyPage = addMockProps(function CompanyPage({ projects }) {
  const { error, data: { data: faces = [] } = {} } = useEmployees()
  useThrowError(error)
  const { title, description } = useSeo()

  const contacts = useRef()

  return (
    <div className="wrap">
      <MetaTags title={title} description={description} />
      <motion.div
        initial={{ y: 40, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ default: { ease: "easeOut", duration: 1, delay: 1.2 } }}
      >
        <h1 className="h1">О компании стройфедерация</h1>
        <div className="md:grid-10 mt-[30px] md:mt-[20px]">
          <div className="text1 col-start-1 col-end-5 md:mt-[40px]">
            Инвестиционно-строительная компания «СтройФедерация» является одной из передовых строительных компаний в
            Башкортостане по качеству строительства многоквартирных жилых домов и внедрению инновационных решений в
            строительной отрасли.
          </div>
          <div className="col-start-8 col-end-11 max-md:mt-[20px]">
            <div className="flex gap-[20px] md:gap-[4px]">
              <div className="md:mt-[80px] xl:mt-[90px]">
                <Button
                  variant="secondary"
                  onClick={() => {
                    contacts.current.scrollIntoView({ behavior: "smooth" })
                  }}
                >
                  контакты компании
                </Button>
              </div>
              <a
                href="https://zakupki.stroifed.ru/"
                target="_blank"
                rel="noreferrer"
                className="block max-md:mt-[57px]"
              >
                <Button variant="secondary">тендеры</Button>
              </a>
            </div>
          </div>
        </div>
        <div className="flex items-center gap-[20px] max-md:hidden md:mt-[15px] xl:mt-[54px]">
          <div className="font-medium leading-[1.2] md:text-[70px] xl:text-[90px]">100 000</div>
          <div className="mb-[20px] self-end font-normal leading-[1.1] md:text-[14px] xl:text-[18px]">м2</div>
          <div className="h2 align-center mb-[-20px] flex gap-[20px]">
            <div className="font-normal md:text-[17px] xl:text-[20px]">—</div> <div>построенного жилья</div>
          </div>
        </div>
        <div className="mt-[30px] md:mt-[40px] xl:mt-[60px]">
          <div className="max-md:hidden">
            <Video src={require("../assets/1.jpg")} videoId="cdwvqLnlfo4" />
          </div>
          <div className="md:hidden">
            <Video src={require("../assets/1m.jpg")} videoId="cdwvqLnlfo4" />
          </div>
        </div>
      </motion.div>
      <AnimatedContainer>
        <div className="md:grid-10 mt-[30px] flex max-md:flex-col md:mt-[40px] xl:mt-[60px]">
          <div className="text1 col-start-1 col-end-5 max-md:order-2 max-md:mt-[40px]">
            От комплекса к комплексу Умные дома от «СтройФедерация» приобретают все новые и качественные решения.
            <br />
            <br /> Панорамное остекление от пола до потолка, кондиционеры и теплый пол в каждой квартире, многоуровневые
            паркинги для жителей, инновационные энергогенерирующие фасады, просторные холлы со своими тренажерными залами и
            комнатами для проведения мероприятий для жителей делают «Умные дома» одними из самых востребованных жилых
            комплексов в городе.
          </div>
          <div className="h2 col-start-6 col-end-10 text-[20px] font-medium leading-[1.6] max-md:order-1">
            В 2012 году ИСК «<span className="text-pink-100">СтройФедерация</span>» вывела на строительный рынок новую
            интеллектуальную категорию жилья многоквартирные «Умные дома».
          </div>
        </div>
      </AnimatedContainer>
      <AnimatedContainer>
        <div className="text2 mt-[60px] text-gray-500 md:mt-[120px] xl:mt-[150px]">Проекты</div>
      </AnimatedContainer>
      <div className="mt-[15px] grid gap-[60px] md:gap-[100px] xl:gap-[150px]">
        {projects.map(project => (
          <AnimatedContainer key={project.id}>
            <CompanyProject project={project} />
          </AnimatedContainer>
        ))}
      </div>
      {faces.length > 0 && (
        <AnimatedContainer>
          <div>
            <div className="text2 mt-[60px] text-gray-500 md:mt-[120px] xl:mt-[150px]">Лица компании</div>
            <div className="md:grid-10 mt-[15px] md:mt-[20px]">
              <div className="h2 col-start-2 col-end-8">
                Наши проекты - это результат работы компетентной команды топ-менеджеров с многоленим опытом управления.
              </div>
              <div className="col-start-8 col-end-9 max-md:flex max-md:justify-end">
                <StroyIcon className="h-[51px] w-[50px] xl:h-[82px] xl:w-[80px]" />
              </div>
            </div>
            <div className="mt-[30px] grid grid-cols-5 gap-[20px] max-md:hidden md:mt-[60px] xl:mt-[80px]">
              {faces.map(face => (
                <CompanyFace key={face.id} face={face} />
              ))}
            </div>
            <div className="mt-[30px] w-[100%] md:hidden">
              <CompanySwiper data={faces} />
            </div>
          </div>
        </AnimatedContainer>
      )}
      <AnimatedContainer>
        <div className="pt-[60px] md:pt-[120px] xl:pt-[150px]" ref={contacts}>
          <div className="text2 text-gray-500">Контакты компании</div>
          <div className="md:grid-10 mt-[10px] max-md:flex max-md:flex-col max-md:gap-[20px] md:mt-[24px] xl:mt-[17px]">
            <div className="col-start-1 col-end-4 grid gap-[20px] md:gap-[46px] xl:gap-[40px]">
              <div>
                <div className="text1">Приемная</div>
                <div className="mt-[6px] text-[14px] font-semibold md:mt-[10px] md:text-[16px] xl:mt-[8px] xl:text-[18px]">
                  <PhoneLink phone='+73472242070'>+7 (347) 224-20-70</PhoneLink>
                </div>
              </div>
              <div>
                <div className="text1">Бухгалтерия</div>
                <div className="mt-[6px] text-[14px] font-semibold md:mt-[10px] md:text-[16px] xl:mt-[8px] xl:text-[18px]">
                  <PhoneLink phone='+73472242070,41'>+7 (347) 224-20-70 <span className="text-[12px]">(доб. 41)</span></PhoneLink>
                </div>
              </div>
              <div>
                <div className="text1">Отдел снабжения</div>
                <div className="mt-[6px] text-[14px] font-semibold md:mt-[10px] md:text-[16px] xl:mt-[8px] xl:text-[18px]">
                <PhoneLink phone='+73472242070,54'>+7 (347) 224-20-70 <span className="text-[12px]">(доб. 54)</span></PhoneLink>
                </div>
              </div>
            </div>
            <div className="col-start-4 col-end-7">
              <div className="col-start-1 col-end-3 grid gap-[20px] md:gap-[46px] xl:gap-[40px]">
                <div>
                  <div className="text1">Юридический отдел </div>
                  <div className="mt-[6px] text-[14px] font-semibold md:mt-[10px] md:text-[16px] xl:mt-[8px] xl:text-[18px]">
                  <PhoneLink phone='+73472242070,20'>+7 (347) 224-20-70 <span className="text-[12px]">(доб. 20)</span></PhoneLink>
                  </div>
                </div>
                <div>
                  <div className="text1">Финансовый отдел</div>
                  <div className="mt-[6px] text-[14px] font-semibold md:mt-[10px] md:text-[16px] xl:mt-[8px] xl:text-[18px]">
                  <PhoneLink phone='+73472242070,43'>+7 (347) 224-20-70 <span className="text-[12px]">(доб. 43)</span></PhoneLink>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-start-7 col-end-10">
              <div className="col-start-1 col-end-3 grid gap-[20px] md:gap-[46px] xl:gap-[40px]">
                <div>
                  <div className="text1 md:whitespace-nowrap">Производственно-технический отдел </div>
                  <div className="mt-[6px] text-[14px] font-semibold md:mt-[10px] md:text-[16px] xl:mt-[8px] xl:text-[18px]">
                  <PhoneLink phone='+73472242070,58'>+7 (347) 224-20-70 <span className="text-[12px]">(доб. 58)</span></PhoneLink>
                  </div>
                </div>
                <div>
                  <div className="text1">Служба технического надзора</div>
                  <div className="mt-[6px] text-[14px] font-semibold md:mt-[10px] md:text-[16px] xl:mt-[8px] xl:text-[18px]">
                  <PhoneLink phone='+73472242070,32'>+7 (347) 224-20-70 <span className="text-[12px]">(доб. 32)</span></PhoneLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AnimatedContainer>
      <AnimatedContainer>
        <div className="relative mt-[60px] md:mt-[120px] xl:mt-[150px]">
          <div className="max-md:aspect-[288/162]">
            <img
              loading="lazy"
              className="block w-[100%] overflow-hidden object-cover max-md:h-[100%] max-md:rounded-[20px]"
              alt="Тендеры"
              src={require("../assets/11.jpg")}
            />
          </div>
          <div className="h2 absolute bottom-[20px] left-[20px] text-white md:bottom-[40px] md:left-[40px]">
            мы готовы
            <br /> к сотрудничеству
          </div>
          <div className="absolute top-0 left-0 right-0 bottom-0 flex items-center max-md:hidden">
            <div className="grid-10 w-[100%]">
              <div className="col-start-8 col-end-9">
                <a href="https://zakupki.stroifed.ru/" target="_blank" rel="noreferrer">
                  <Button>Тендеры</Button>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-[10px] flex justify-center md:hidden">
          <a href="https://zakupki.stroifed.ru/" target="_blank" rel="noreferrer">
            <Button>Тендеры</Button>
          </a>
        </div>
      </AnimatedContainer>
      <Cover />
    </div>
  )
}, mock)

const AnimatedContainer = ({ children }) => (
  <motion.div
    initial={{ y: 100, opacity: 0 }}
    whileInView={{ y: 0, opacity: 1 }}
    transition={{ default: { ease: "easeOut", duration: 0.6 } }}
  >
    {children}
  </motion.div>
)

const PhoneLink = ({ phone, children}) => (
  <a href={`tel:${phone}`} className="whitespace-nowrap">
    { children } 
</a>
)
