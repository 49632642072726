import disableScroll from "disable-scroll"
import { motion } from "framer-motion"
import { useEffect } from "react"

export function Cover() {
  useEffect(() => {
    disableScroll.on()
  }, [])

  return (
    <>
      <motion.div
        initial={{ y: 0 }}
        animate={{ y: "-100%" }}
        transition={{
          default: { ease: [0.81, 0, 0.44, 1.01], duration: 0.9, delay: 1 }
        }}
        className="fixed top-0 bottom-0 right-0 left-0 z-[200] bg-gray-100"
        onAnimationComplete={() => {
          disableScroll.off()
        }}
      />
      <motion.div
        initial={{ y: 0 }}
        animate={{ y: "-100%" }}
        transition={{ default: { ease: [0.81, 0, 0.44, 1.01], duration: 0.9, delay: 0.1 } }}
        className="fixed top-0 bottom-0 right-0 left-0 z-[200] bg-white"
      />
    </>
  )
}
