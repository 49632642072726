import { ReactComponent as ArrowIcon } from "../assets/arrow.svg"
import { cx } from "shared/helpers"

export function Button({ variant = "primary", size = "large", startIcon, endIcon, children, ...props }) {
  const variantCx = {
    primary: "bg-pink-100 text-white",
    secondary: "bg-gray-600 text-pink-100",
    gray: "bg-gray-600"
  }[variant]

  const sizeCx = {
    large: "h-[120px] w-[120px] md:h-[140px] md:w-[140px] xl:h-[170px] xl:w-[170px]",
    middle: "h-[140px] w-[140px]",
    small: "h-[40px] w-[40px]"
  }[size]

  const buttonCx = cx(variantCx, sizeCx)

  return (
    <button {...props} className={cx("cursor-pointer overflow-hidden rounded-[50%]", buttonCx)}>
      <div className="h-[200%] w-[100%] duration-300 hover:translate-y-[-50%]">
        <div className="button-text flex h-[50%] w-[100%] items-center justify-center p-[10px]">
          <span>{startIcon}</span>
          {children}
          <span>{endIcon}</span>
        </div>
        <div className="flex h-[50%] w-[100%] items-center justify-center">
          <ArrowIcon className="block h-[20px] w-auto md:h-[24px] xl:h-[30px]" />
        </div>
      </div>
    </button>
  )
}
