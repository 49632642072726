import { cx } from "shared/helpers"
import { http } from "shared/http"
import { useState } from "react"

export function Suggest({ onSelect, className }) {
  const [suggestIsOpened, setSuggestIsOpened] = useState(false)
  const [results, setResults] = useState([])
  const [inputValue, setInputValue] = useState("")
  const [active, setActive] = useState(null)

  const onSearch = e => {
    if (e.target.value) {
      http.get(`/daData/address?query=${e.target.value}`).then(response => {
        if (response?.data && response?.data[0]) setResults(response?.data[0])
      })
      setInputValue(e.target.value)
      if (results.length > 0) {
        setSuggestIsOpened(true)
      }
    } else {
      setInputValue("")
      setSuggestIsOpened(false)
    }
    setActive(null)
  }

  const onSelectItem = item => {
    onSelect(item)
    setInputValue(item.value)
    setSuggestIsOpened(false)
  }

  const handleKeyDown = event => {
    if (event.key === "ArrowDown") {
      if (active + 1 < results.length) {
        setActive(active !== null ? active + 1 : 0)
      }
    }
    if (event.key === "ArrowUp" && active !== null) {
      if (active > 0) setActive(active - 1)
    }
    if (event.key === "Enter" && active !== null) {
      onSelectItem(results[active])
    }
  }

  return (
    <div className={cx("relative", className)}>
      <input
        className="input block w-[100%]"
        placeholder="введите свой адрес"
        type="text"
        onInput={onSearch}
        value={inputValue}
        onKeyDown={handleKeyDown}
      />
      {results.length > 0 && suggestIsOpened && (
        <div className="absolute top-[60px] left-0 right-0 z-[10] mt-[-1px] max-h-[400px] overflow-y-auto rounded-[10px] border border-gray-300 bg-white">
          {results.map((item, index) => (
            <div
              key={index}
              tabIndex={index}
              className={cx("cu rounded-[10px] py-[10px] px-[20px]", { "bg-gray-600": index === active })}
              onClick={() => {
                onSelectItem(item)
              }}
            >
              {item.value}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
