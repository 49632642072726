import { AnimatePresence, motion } from "framer-motion"
import { Modal } from "../components/modal"
import { ReactComponent as PlayIcon } from "../assets/play.svg"
import { ReactComponent as PlayTextIcon } from "../assets/play-text.svg"
import YouTube from "react-youtube"
import { useState } from "react"

export function Video({ src, videoId }) {
  const [modalIsOpen, setModalIsOpen] = useState()
  const open = () => {
    setModalIsOpen(true)
  }
  const close = () => {
    setModalIsOpen(false)
  }

  const opts = {
    playerVars: {
      autoplay: 1
    }
  }

  return (
    <>
      <div className="group relative relative cursor-pointer overflow-hidden rounded-[20px]" onClick={open}>
        <img loading="lazy" className="block" src={src} alt="" />
        <div className="absolute top-0 left-0 right-0 bottom-0 bg-black opacity-[0.15] transition" />
        <div className="absolute top-0 right-0 left-0 bottom-0 m-[auto] h-[75px] w-[75px] cursor-pointer md:h-[90px] md:w-[90px] xl:h-[125px] xl:w-[125px]">
          <div className="rotate opacity-0 transition group-hover:opacity-100">
            <PlayTextIcon className="h-[75px] w-[75px] md:h-[90px] md:w-[90px] xl:h-[125px] xl:w-[125px]" />
          </div>
          <div className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center">
            <PlayIcon className="block h-[20px] w-[20px] md:h-[22px] md:w-[22px] xl:h-[24px] xl:w-[24px]" />
          </div>
        </div>
      </div>
      <AnimatePresence>
        {modalIsOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ default: { ease: "easeOut", duration: 0.3 } }}
            className="fixed top-0 bottom-0 right-0 left-0 z-[101] block bg-gray-100/70 max-md:overflow-y-auto"
            onClick={close}
          >
            <Modal>
              <motion.div
                initial={{ opacity: 0, scale: 0.7 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.7 }}
                transition={{ default: { ease: "easeOut", duration: 0.3, delay: 0.7 } }}
                className="w-[100%] overflow-hidden rounded-[20px] md:h-[100%]"
              >
                <YouTube
                  videoId={videoId}
                  iframeClassName="w-[100%] md:h-[100%]"
                  style={{ height: "100%" }}
                  opts={opts}
                />
              </motion.div>
            </Modal>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  )
}
