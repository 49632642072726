import "swiper/css"
import "swiper/css/autoplay"
import "swiper/css/effect-fade"
import "swiper/css/navigation"
import "swiper/css/controller"
import "swiper/css/lazy"

import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Autoplay, Controller, EffectFade, Lazy, Navigation } from "swiper"
import { useEffect, useState } from "react"
import { ReactComponent as ArrowNextIcon } from "../assets/arrow-next.svg"
import { ReactComponent as ArrowPrevIcon } from "../assets/arrow-prev.svg"
import { cx } from "shared/helpers"

SwiperCore.use([Autoplay, EffectFade, Navigation, Lazy, Controller])

export function Gallery({ files, fullscreen = false, autoplay = false }) {
  const [active, setActive] = useState(0)
  const [swiper, setSwiper] = useState(null)
  const [swiperThumbs, setSwiperThumbs] = useState(null)

  let params = {
    preloadImages: false,
    lazy: true,
    navigation: {
      prevEl: ".swiper-prev",
      nextEl: ".swiper-next"
    },
    effect: "fade",
    loop: false,
    speed: 500,
    slidesPerView: 1,
    onSwiper: setSwiper,
    autoplay: autoplay
      ? {
          delay: 3000,
          disableOnInteraction: false
        }
      : false,
    onSlideChange: swiper => setActive(swiper.realIndex)
  }
  let thumbsParams = {
    slideToClickedSlide: true,
    slidesPerView: "auto",
    centeredSlides: true,
    spaceBetween: 15,
    onSwiper: setSwiperThumbs,
    style: {
      height: "80px"
    }
  }

  useEffect(() => {
    if (swiper && swiperThumbs) {
      if (swiper.controller) swiper.controller.control = swiperThumbs
      if (swiperThumbs.controller) swiperThumbs.controller.control = swiper
    }
  }, [swiper, swiperThumbs])

  return (
    <>
      <div className="max-md:hidden md:flex md:h-[100%] md:max-h-screen md:flex-col md:gap-[40px] xl:gap-[75px]">
        <div className="flex-auto">
          <div className="grid-10 h-[100%] items-center">
            <div className="col-start-2 col-end-10 h-[100%]">
              <Swiper
                className={cx("h-[100%]", { "top-0 left-0 right-0 bottom-0 z-[1]": fullscreen })}
                style={{ position: fullscreen ? "fixed" : "relative" }}
                {...params}
              >
                {files.map(image => (
                  <SwiperSlide key={image.hash} className="relative h-[100%]">
                    <div className="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center bg-white">
                      <img
                        loading="lazy"
                        className={cx("h-[100%] w-[100%]", fullscreen ? "object-cover" : "object-contain")}
                        alt=""
                        src={image.url}
                      />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
            <div
              className={cx("z-[2] col-start-10 col-end-11", {
                "self-end": fullscreen
              })}
            >
              <div
                className={cx({
                  "mb-[10px] mr-[-60px] flex h-[140px] w-[140px] items-center justify-center rounded-[50%] bg-white/50 transition hover:bg-white/70 xl:mb-[20px] xl:mr-[-50px] xl:h-[170px] xl:w-[170px]":
                    fullscreen
                })}
              >
                <div>
                  <div className="text-right text-[12px] leading-[1.55] md:text-[14px] xl:text-[18px]">
                    {active + 1}/{files.length}
                  </div>
                  <div className="mt-[6px] flex justify-end gap-[10px] md:mt-[10px] xl:gap-[14px]">
                    <div
                      className={cx("swiper-prev cursor-pointer", {
                        "text-gray-100/50": active === 0
                      })}
                    >
                      <ArrowPrevIcon />
                    </div>
                    <div
                      className={cx("swiper-next cursor-pointer", {
                        "text-gray-100/50": active + 1 === files.length
                      })}
                    >
                      <ArrowNextIcon />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {!fullscreen && (
          <div className="flex-none">
            <Swiper {...thumbsParams} className="w-[100%]">
              {files.map((image, index) => (
                <SwiperSlide key={image.hash} style={{ width: 80 }}>
                  <img
                    loading="lazy"
                    alt=""
                    key={image.src}
                    className={cx("block h-[100%] w-[100%] object-contain transition", {
                      "opacity-30": swiperThumbs && index !== swiperThumbs.realIndex
                    })}
                    src={image.url}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        )}
      </div>
      <div className="mt-[20px] grid gap-[30px] md:hidden">
        {files.map(image => (
          <div key={image.hash}>
            <img loading="lazy" className="block" alt="" src={image.url} />
          </div>
        ))}
      </div>
    </>
  )
}
