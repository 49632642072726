export const plansSwiper = [
  {
    id: 0,
    src: require("../assets/pm2.jpg")
  },
  {
    id: 1,
    src: require("../assets/pm3.jpg")
  }
]
