import { ReactComponent as ArrowDownIcon } from "../assets/arrow-down.svg"
import { ReactComponent as ArrowIcon } from "../assets/arrow.svg"

export function IconButton({ icon, ...props }) {
  const svg = ICONS[icon] ?? null
  return (
    <button
      {...props}
      className="h-[50px] w-[50px] overflow-hidden rounded-[50%] bg-gray-600 text-pink-100 md:h-[64px] md:w-[64px] xl:h-[74px] xl:w-[74px]"
    >
      <div className="h-[200%] w-[100%] duration-300 group-hover:translate-y-[-50%]">
        <div className="flex h-[50%] w-[100%] items-center justify-center">{svg}</div>
        <div className="flex h-[50%] w-[100%] items-center justify-center">{svg}</div>
      </div>
    </button>
  )
}

const ICONS = {
  arrowUp: <ArrowIcon className="block h-[30px] w-auto md:h-[40px] xl:h-[50px]" />,
  arrowDown: <ArrowDownIcon className="block h-[30px] w-auto md:h-[40px] xl:h-[50px]" />
}
