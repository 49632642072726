import { Button } from "shared/components/navigation"
import { Link } from "react-router-dom"
import { ReactComponent as StroyIcon } from "../assets/stroy.svg"
import { Video } from "shared/components/video"

export function Developer() {
  return (
    <div className="md:grid-10 mt-[60px] md:mt-[120px] xl:mt-[150px]">
      <div className="col-start-1 col-end-5 flex flex-col">
        <div className="text2 flex-none text-gray-500">Застройщик</div>
        <div className="h-[100%] flex-auto gap-[24px] pt-[15px] md:flex md:gap-[20px] md:pt-[20px] xl:gap-[40px]">
          <div className="max-md:flex max-md:gap-[24px]">
            <StroyIcon className="h-[29px] max-md:w-[29px]" />
            <div className="h1 md:hidden">Стройфедерация</div>
          </div>
          <div className="mt-[40px] md:hidden">
            <Video src={require("../assets/v2.jpg")} videoId="cdwvqLnlfo4" />
          </div>
          <div>
            <div className="flex h-[100%] flex-col max-md:mt-[30px]">
              <div className="flex-auto">
                <div className="h1 max-md:hidden">Стройфедерация</div>
                <div className="text1 md:mt-[40px] xl:mt-[78px]">
                  Инвестиционно-строительная компания «СтройФедерация» является одной из передовых строительных компаний
                  в Башкортостане по качеству строительства многоквартирных жилых домов и внедрению инновационных
                  решений в строительной отрасли.
                </div>
              </div>
              <div className="mt-[30px] flex flex-none justify-center md:mt-[20px]">
                <Link to="/company">
                  <Button variant="secondary">о компании</Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-start-6 col-end-11 max-md:hidden">
        <Video src={require("../assets/v2.jpg")} videoId="cdwvqLnlfo4" />
      </div>
    </div>
  )
}
