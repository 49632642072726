import { ReactComponent as CloseIcon } from "../assets/close.svg"

export function Modal({ children, onClose = () => {} }) {
  return (
    <div className="flex h-[100%] flex-col justify-center gap-[30px] py-[20px] max-md:px-[16px] md:gap-[30px] md:pb-[80px] md:pt-[40px]">
      <div className="flex flex-none justify-center">
        <div className="cursor-pointer">
          <CloseIcon className={`block h-[20px] w-[20px] text-white md:h-[24px] md:w-[24px]`} onClick={onClose} />
        </div>
      </div>
      <div className="flex w-[100%] flex-auto justify-center">
        <div className="h-[auto] w-[100%] md:w-[70%]">{children}</div>
      </div>
    </div>
  )
}
