import { Helmet, HelmetProvider } from "react-helmet-async"

import { SWRConfig } from "swr"
import { fetcher } from "shared/http"
import { useEffect } from "react"
import { useSettings } from "shared/store"

export function App({ children }) {
  const { loadSettings } = useSettings()

  useEffect(() => {
    loadSettings()
  }, [loadSettings])

  return (
    <SWRConfig value={{ fetcher }}>
      <HelmetProvider>
        <Helmet>
          <title>Квартиры в Новом Умном доме от ИСК "СтройФедерация"</title>
          <meta name="description" content="Покупайте квартиры с чистовой отделкой в Новом Умном доме. Современная инфраструктура, большой паркинг, удобное местоположение. Звоните!" />
        </Helmet>
        {children}
      </HelmetProvider>
    </SWRConfig>
  )
}
