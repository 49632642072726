import { ReactComponent as BurgerIcon } from "../assets/burger.svg"
import { ReactComponent as CloseIcon } from "../assets/close.svg"
import { Link } from "react-router-dom"
import { ReactComponent as LogoIcon } from "../assets/logo.svg"
import { Menu } from "./menu"
import { ReactComponent as StroyIcon } from "../assets/stroy.svg"
import { useModal } from "shared/store"

export function HeaderSm() {
  const { openModal, modalIsOpen, closeModal } = useModal()

  const toggleMenu = () => {
    if (modalIsOpen) closeModal()
    else openModal(<Menu onClose={closeModal} />)
  }

  return (
    <div className="z-[10] flex-none bg-white">
      <div className="flex h-[var(--header-height-sm)] items-center px-[16px]">
        <div className="flex-1">
          <Link to="/">
            <LogoIcon className="h-[23px] w-[74px]" />
          </Link>
        </div>
        <div className="flex flex-1 justify-center">
          <div className="p-[10px]" onClick={toggleMenu}>
            {modalIsOpen ? <CloseIcon className="h-[20px] w-[20px]" /> : <BurgerIcon />}
          </div>
        </div>
        <div className="flex flex-1 items-center justify-end gap-[20px]">
          <div className="mb-[-6px] text-[12px] font-medium uppercase leading-[1.5]">
            <a href="https://zakupki.stroifed.ru" target="_blank" rel="noreferrer">
              <div>тендеры</div>
            </a>
          </div>
          <Link to="/company">
            <StroyIcon />
          </Link>
        </div>
      </div>
    </div>
  )
}
