import { Map, Placemark, YMaps } from "@pbe/react-yandex-maps"
import { useMemo, useRef } from "react"

import { Button } from "shared/components/navigation"
import { Cover } from "shared/components/layout"
import { MetaTags } from "shared/components/seo"
import { Suggest } from "shared/components/suggest"
import { addMockProps } from "shared/helpers"
import { calcLatLngCenter } from "../helpers"
import { mock } from "../mock"
import { motion } from "framer-motion"
import { sanitizeHTML } from "shared/helpers"
import { useContacts } from "shared/store"
import { useSeo } from "shared/hooks"
import { useState } from "react"

export const ContactsPage = addMockProps(function ContactsPage({ workHours, complexLocation, companyLocation }) {
  const { phone, address } = useContacts()
  const latLngs = useRef([complexLocation, companyLocation])
  const mapCenter = useMemo(() => calcLatLngCenter(latLngs.current), [])
  const [targetCoords, setTargetCoords] = useState([])
  const { title, description } = useSeo()

  const buildRoute = () => {
    if (targetCoords.length > 0)
      window.open(
        `https://yandex.ru/maps/?rtext=${complexLocation[0]},${complexLocation[1]}~${targetCoords[0]},${targetCoords[1]}`,
        "_blank"
      )
  }

  return (
    <div className="wrap">
      <MetaTags title={title} description={description} />
      <motion.div
        initial={{ y: 40, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ default: { ease: "easeOut", duration: 1, delay: 1.2 } }}
      >
        <h1 className="h1">Контакты</h1>
        <div className="md:grid-10 mt-[30px] md:mt-[42px] xl:mt-[60px]">
          <div className="col-start-1 col-end-5">
            <div className="text2 text-gray-500">Местоположение</div>
            <div className="h2 mt-[10px] md:mt-[19px] xl:mt-[4px]">
              <span
                className="whitespace-pre-line"
                dangerouslySetInnerHTML={{ __html: sanitizeHTML(address.complex) }}
              />
            </div>
            <div className="h7 mt-[10px] md:mt-[19px] xl:mt-[15px]">{phone}</div>
          </div>
          <div className="col-start-5 col-end-11 max-md:mt-[38px]">
            <div className="text2 text-gray-500">Офис продаж</div>
            <div className="h2 mt-[10px] md:mt-[19px] xl:mt-[4px]">
              <span className="whitespace-pre-line">{address.office}</span>
            </div>
            <div className="h7 mt-[10px] md:mt-[19px] xl:mt-[15px]">{workHours.join(",")}</div>
          </div>
        </div>
        <div className="md:grid-10 mt-[38px] md:mt-[58px] xl:mt-[80px]">
          <div className="col-start-1 col-end-7">
            <div className="relative aspect-[288/322] overflow-hidden rounded-[20px] bg-gray-300 md:aspect-[712/322] xl:aspect-[1044/472]">
              <YMaps>
                <Map
                  width="100%"
                  height="100%"
                  options={{ suppressMapOpenBlock: true }}
                  defaultState={{ center: mapCenter, zoom: 16 }}
                  modules={["geoObject.addon.balloon"]}
                >
                  <Placemark
                    geometry={complexLocation}
                    options={{
                      iconLayout: "default#image",
                      iconImageSize: [56, 62],
                      iconImageOffset: [-56 / 2, -62],
                      iconImageHref: require("../assets/complex-mark.svg").default,
                      balloonPanelMaxMapArea: 0
                    }}
                    properties={{
                      balloonContentHeader: "Умный дом Гелиос",
                      balloonContentBody: address.complex
                    }}
                  />
                  <Placemark
                    geometry={companyLocation}
                    options={{
                      iconLayout: "default#image",
                      iconImageSize: [56, 62],
                      iconImageOffset: [-56 / 2, -62],
                      iconImageHref: require("../assets/company-mark.svg").default,
                      balloonPanelMaxMapArea: 0
                    }}
                    properties={{
                      balloonContentHeader: "Офис продаж",
                      balloonContentBody: address.office
                    }}
                  />
                </Map>
              </YMaps>
              <div className="button-text absolute bottom-[20px] right-[20px] hover:text-pink-100 md:bottom-[30px] md:left-[30px]">
                <a
                  href="https://yandex.ru/maps/172/ufa/house/ulitsa_gali_ibragimova_5_3/YUwYfw5mTk0HQFtufXtzdHRmYw==/inside/?l=sat%2Cskl&ll=55.997161%2C54.725760&tab=inside&z=17"
                  target="_blank"
                  rel="noreferrer"
                  className="max-md:hidden"
                >
                  открыть на картах
                </a>
                <a
                  href="yandexmaps://maps.yandex.ru/?ll=55.997161,54.725760&tab=inside&z=17"
                  target="_blank"
                  rel="noreferrer"
                  className="md:hidden"
                >
                  открыть на картах
                </a>
              </div>
            </div>
          </div>
          <div className="col-start-8 col-end-11 max-md:mt-[30px]">
            <div className="flex items-center gap-[20px]">
              <div className="flex-auto">
                <Suggest
                  onSelect={item => {
                    setTargetCoords([item.data.geoLat, item.data.geoLon])
                  }}
                />
              </div>
            </div>
            <div className="mt-[30px] max-md:flex max-md:items-center max-md:gap-[30px] xl:mt-[60px]">
              <Button onClick={buildRoute}>построить маршрут</Button>
            </div>
          </div>
        </div>
      </motion.div>
      <Cover />
    </div>
  )
}, mock.contacts)
