import { Button } from "shared/components/navigation"
import { ReactComponent as CloseIcon } from "../assets/close.svg"
import { Link } from "react-router-dom"
import { ReactComponent as VkIcon } from "../assets/vk.svg"
import { ReactComponent as YoutubeIcon } from "../assets/youtube.svg"
import { sanitizeHTML } from "shared/helpers"
import { useContacts } from "shared/store"
import { useNavigate } from "react-router-dom"

export function Menu({ onClose }) {
  const { phone, address } = useContacts()
  return (
    <div className="flex h-[100%] flex-col gap-[60px] px-[16px] pt-[30px] pb-[18px] pt-[100px] max-md:overflow-auto md:gap-[50px] md:px-[100px] md:pt-[33px] md:pb-[60px] xl:pb-[100px]">
      <div className="flex-auto">
        <div className="flex cursor-pointer justify-center max-md:hidden">
          <CloseIcon onClick={onClose} />
        </div>
        <div className="flex grid-cols-1 flex-col gap-[20px] md:mt-[60px] md:grid md:grid-cols-10 xl:mt-[120px]">
          <div className="col-start-1 col-end-6">
            <div className="grid gap-[20px] md:gap-[30px] xl:gap-[40px]">
              <MenuOption href="/" onClick={onClose}>
                о проекте
              </MenuOption>
              <MenuOption href="/floor/1/1" onClick={onClose}>
                выбрать квартиру
              </MenuOption>
              <MenuOption href="/company" onClick={onClose}>
                о компании
              </MenuOption>
              <MenuOption href="/news" onClick={onClose}>
                новости
              </MenuOption>
              <MenuOption href="/contacts" onClick={onClose}>
                контакты
              </MenuOption>
            </div>
          </div>
          <div className="col-start-7 col-end-11">
            <div className="grid gap-[20px] md:gap-[30px] xl:gap-[40px]">
              <MenuLinkBlank href="https://zakupki.stroifed.ru/">Тендеры</MenuLinkBlank>
              <MenuLinkBlank href="https://наш.дом.рф/сервисы/каталог-новостроек/объект/43319">
                Проектная декларация
              </MenuLinkBlank>
              <MenuLinkBlank
                href="https://наш.дом.рф/сервисы/каталог-новостроек/объект/43319"
              >
                Ход строительства
              </MenuLinkBlank>
              <MenuLinkBlank href="https://ucams.ufanet.ru/api/internal/embed/1653372953YDQ270/?ttl=3600&autoplay=true&mute=true">
                Онлайн-камера
              </MenuLinkBlank>
            </div>
          </div>
        </div>
      </div>
      <div className="flex-none">
        <div className="flex gap-[30px] max-md:flex-col md:items-end md:gap-[20px]">
          <div className="flex-1 max-md:order-2">
            <div className="flex gap-[20px]">
              <div className="flex-none">
                <Link to="/">
                  <Button variant="secondary">
                    <VkIcon />
                  </Button>
                </Link>
              </div>
              <div className="flex-none">
                <Link to="/">
                  <Button variant="gray" size="small">
                    <YoutubeIcon />
                  </Button>
                </Link>
              </div>
            </div>
          </div>
          <div className="flex-1 max-md:order-1 md:text-right">
            <a href={`tel:${phone}`}>
              <div className="h1">{phone}</div>
            </a>
            <div
              className="h6 mt-[5px] whitespace-pre-line md:mt-[20px] xl:mt-[30px]"
              dangerouslySetInnerHTML={{ __html: sanitizeHTML(address.complex) }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const MenuOption = ({ href, children, onClick }) => {
  const navigate = useNavigate()
  return (
    <div
      className="h2 cursor-pointer transition hover:text-pink-100"
      onClick={() => {
        navigate(href)
        onClick()
      }}
    >
      {children}
    </div>
  )
}

const MenuLinkBlank = ({ href, children }) => (
  <a href={href} target="_blank" rel="noreferrer" className="h2 transition hover:text-pink-100">
    {children}
  </a>
)
