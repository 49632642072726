export function calcLatLngCenter(latLngs) {
  const count = latLngs.length
  const sum = latLngs.reduce(
    (acc, cur) => {
      return {
        lat: acc.lat + cur[0],
        lng: acc.lng + cur[1]
      }
    },
    { lat: 0, lng: 0 }
  )
  return [sum.lat / count, sum.lng / count]
}
