import "swiper/css"
import "swiper/css/autoplay"

import { AnimatePresence, motion } from "framer-motion"
import SwiperCore, { Autoplay, Navigation } from "swiper"

import { AdvantagesSwiper } from "./advantages-swiper"
import { ReactComponent as StarIcon } from "../assets/star.svg"
import { useState } from "react"

SwiperCore.use([Autoplay, Navigation])

export function Advantages({ advantages }) {
  const [active, setActive] = useState()
  const [swiperObject, setSwiperObject] = useState()

  const onChangeSlide = active => {
    setActive(active)
  }

  return (
    <div className="mt-[60px] md:mt-[120px] xl:mt-[150px]">
      <div className="text2 flex-none text-gray-500">Преимущества</div>
      <div className="md:grid-10 mt-[15px] md:mt-[20px] xl:mt-[40px]">
        <div className="col-start-1 col-end-4">
          <div className="border-1 border-t border-gray-300">
            {advantages.map((advantage, index) => (
              <div
                key={advantage.id}
                className="border-1 h7 flex cursor-pointer items-center border-b border-gray-300 py-[15px] hover:text-pink-100 md:py-[20px] xl:py-[25px]"
                onClick={() => {
                  swiperObject.slideTo(index)
                }}
              >
                <div className="flex-auto">{advantage.name}</div>
                <AnimatePresence>
                  {index === active && (
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ default: { ease: "easeOut", duration: 0.5 } }}
                      className="flex-none"
                    >
                      <StarIcon className="h-[11px] w-[11px] xl:h-[14px] xl:w-[14px]" />
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            ))}
          </div>
        </div>
        <div className="col-start-4 col-end-11">
          <AdvantagesSwiper data={advantages} onChangeSlide={onChangeSlide} onSwiper={setSwiperObject} />
        </div>
      </div>
    </div>
  )
}
